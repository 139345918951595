export interface IconProps {
  name: Icons;
  height?: number;
  width?: number;
  fill?: string;
}
export enum Icons {
  EyeOpen = 'eye-open',
  EyeClose = 'eye-close',
  Loading = 'loading',
  Add = 'add',
  Upload = 'upload',
  Delete = 'delete',
  PDF = 'pdf',
  Bell = 'bell',
  CaretDown = 'caret-down',
  CaretUp = 'caret-up',
  Dashboard = 'dashboard',
  Vendor = 'vendor',
  Invoices = 'invoices',
  Product = 'product',
  Transaction = 'transaction',
  Audit = 'audit',
  Settings = 'settings',
  Logout = 'logout',
  Search = 'search',
  Filter = 'filter',
  More = 'more',
  Attachment = 'attachment',
  AddCircle = 'add-circle',
  AddUser = 'add-user',
  Close = 'close',
  Success = 'success',
  EditCricle = 'edit-circle',
  EditPencil = 'edit-pencil',
  Camera = 'camera',
  SingleFile = 'single-file',
  MultipleFile = 'multiple-file',
  CheckMark = 'check-mark',
  Download = 'download',
  Rating = 'rating',
  Draft = 'draft',
  Sent = 'sent',
  Awaiting = 'awaiting',
  Approve = 'approve',
  Payment = 'payment',
  File = 'file',
  Error = 'error',
  Mark = 'mark',
  Card = 'card',
  DeleteCircle = 'delete-circle',
  People = 'people',
  HandCoin = 'hand-coin',
  Growth = 'growth',
  Twitter = 'twitter',
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
  Menu = 'menu',
}
