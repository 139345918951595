import React from 'react';
import ShowCount from './ShowCount';

type Props = {
  totalCount: any;
  dataLimit: any;
  currentPage: any;
};

const PaginationCount: React.FC<Props> = ({
  totalCount,
  dataLimit,
  currentPage,
}: any) => {
  return (
    <ShowCount
      totalCount={totalCount}
      itemsPerPage={dataLimit}
      currentPage={currentPage}
    />
  );
};

export default PaginationCount;
