import { useEffect, useState } from 'react';
import Button from '../../../../../Components/Form/Button';
import { Icon, Icons } from '../../../../../Components/Icon';
import EditCompanyInfo from './EditCompanyInfo';
import EditCompanyBank from './EditCompanyBank';
import EditCompanyAddress from './EditCompanyAddress';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { useUpdateBusiness } from '../../../../../hooks/queries-and-mutations/business';
import { FormDto } from '../../../../../types/business.type';
import { toast } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import DropDown from '../../../../../Components/Animations/DropDown';

export default function General() {
  const [showCompanyInfoEdit, setShowCompanyInfoEdit] = useState(false);
  const [showCompanyBankEdit, setShowCompanyBankEdit] = useState(false);
  const [showCompanyAddressEdit, setShowCompanyAddressEdit] = useState(false);
  const [accordian, setAccordian] = useState({
    info: false,
    bank: false,
    address: false,
  });
  const { docs } = useAppSelector(selectBusiness);

  const { mutate, isSuccess, isError } = useUpdateBusiness();

  const handleSubmit = (forms: FormDto) => {
    let payload;
    if (forms.banks) {
      payload = {
        ...forms,
        companyAddresss: docs[0].businessID.addresses,
        type: docs[0].businessID.type,
        name: docs[0].businessID?.name ?? '',
        email: docs[0].businessID?.email ?? '',
        phone: docs[0].businessID?.phone ?? '',
        rcNumber: docs[0].businessID?.rcNumber ?? '',
        industryType: docs[0].businessID?.industryType ?? '',
        companySize: docs[0].businessID?.companySize ?? '',
      };
    } else {
      payload = {
        ...forms,
        banks: docs[0].businessID.bankInfo.map((bk) => ({
          ...bk,
          bank: { value: bk.bankCode, label: bk.bankName },
        })),
      };
    }

    const formData = new FormData();
    formData.append('type', 'buyer_supplier');
    formData.append('name', payload.name);
    formData.append('email', payload.email);
    formData.append('phone', payload.phone);
    formData.append('industryType', payload.industryType);
    formData.append('companySize', payload.companySize);
    formData.append('rcNumber', payload.rcNumber);
    for (let i = 0; i < payload.companyAddresss.length; i++) {
      const addressObj = payload.companyAddresss[i];
      formData.append('addresses', JSON.stringify(addressObj));
    }

    for (let i = 0; i < docs[0].businessID.directorsDetails.length; i++) {
      const directorObj = docs[0].businessID.directorsDetails[i];
      formData.append('directorsDetails', JSON.stringify(directorObj));
    }

    for (let i = 0; i < docs[0].businessID.repsDetails.length; i++) {
      const addressObj = docs[0].businessID.repsDetails[i];
      formData.append('repsDetails', JSON.stringify(addressObj));
    }

    for (let i = 0; i < payload.banks.length; i++) {
      const bank = payload.banks[i];
      const bankObj = {
        ...bank,
        bankName: bank.bank.label,
        bankCode: bank.bank.value,
      };
      if ('bank' in bankObj) {
        delete (bankObj as { bank?: any }).bank;
      }
      formData.append('bankInfo', JSON.stringify(bankObj));
    }

    mutate({ payload: formData, businessId: docs[0].businessID._id });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setShowCompanyInfoEdit(false);
      setShowCompanyBankEdit(false);
      setShowCompanyAddressEdit(false);
      toast.success(
        'Business update request submitted successfully and is under review.'
      );
    }
  }, [isError, isSuccess]);

  const handleAccordian = (field: string, value: boolean) => {
    setAccordian((prevState) => ({ ...prevState, [field]: !value }));
  };

  return (
    <div className="w-full pb-8">
      <div className="py-5 border-b pl-9">
        <p className="text-2xl font-semibold text-black font-inter">General</p>
      </div>
      {docs.length > 0 && (
        <div>
          {/* Compant Information */}
          <div className="px-12 py-5">
            <div className="border-b">
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleAccordian('info', accordian.info)}
                className="flex items-center justify-between mb-8"
              >
                <span className="text-xl font-semibold text-black font-inter">
                  Company Information
                </span>
                <button type="button" className={`${accordian.info ? 'rotate-180' : ''}`}>
                  <Icon name={Icons.CaretDown} />
                </button>
              </div>
              <AnimatePresence>
                {accordian.info && (
                  <DropDown>
                    <div>
                      {showCompanyInfoEdit ? (
                        <EditCompanyInfo
                          business={docs[0]?.businessID}
                          onSave={(value) => handleSubmit(value as FormDto)}
                        />
                      ) : (
                        <div className="pb-9">
                          <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium text-black/40 font-inter">
                                Company Name
                              </span>
                              <span className="text-lg font-medium text-black font-inter">
                                {docs[0]?.businessID?.name ?? 'N/A'}
                              </span>
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium text-black/40 font-inter">
                                Company Email
                              </span>
                              <span className="text-lg font-medium text-black font-inter">
                                {docs[0]?.businessID?.email ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                          <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium text-black/40 font-inter">
                                Phone Number
                              </span>
                              <span className="text-lg font-medium text-black font-inter">
                                {docs[0]?.businessID?.phone ?? 'N/A'}
                              </span>
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium text-black/40 font-inter">
                                Registration Number
                              </span>
                              <span className="text-lg font-medium text-black font-inter">
                                {docs[0]?.businessID?.rcNumber ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                          <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium text-black/40 font-inter">
                                Industry Type
                              </span>
                              <span className="text-lg font-medium text-black font-inter">
                                {docs[0]?.businessID?.industryType ?? 'N/A'}
                              </span>
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium text-black/40 font-inter">
                                Company Size
                              </span>
                              <span className="text-lg font-medium text-black font-inter">
                                {docs[0]?.businessID?.companySize ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                          {!showCompanyInfoEdit && (
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              label="Edit"
                              className="mt-10 bg-transparent border-[#0353A4] text-[#0353A4]"
                              icon={<Icon name={Icons.EditPencil} />}
                              iconPosition="left"
                              iconLabelSpacing={5}
                              onClick={() => setShowCompanyInfoEdit(true)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </DropDown>
                )}
              </AnimatePresence>
            </div>
          </div>
          {/* Company Bank Details */}
          <div className="px-12 py-1">
            <div className="border-b">
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleAccordian('bank', accordian.bank)}
                className="flex items-center justify-between mb-8"
              >
                <span className="text-xl font-semibold text-black font-inter">
                  Company Bank Details
                </span>
                <button type="button" className={`${accordian.bank ? 'rotate-180' : ''}`}>
                  <Icon name={Icons.CaretDown} />
                </button>
              </div>
              <AnimatePresence>
                {accordian.bank && (
                  <DropDown>
                    <div>
                      {showCompanyBankEdit ? (
                        <EditCompanyBank
                          business={docs[0]?.businessID}
                          onSave={(value) => handleSubmit(value as FormDto)}
                        />
                      ) : (
                        <div className="pb-9">
                          {(docs[0]?.businessID?.bankInfo ?? [])?.map((bank) => (
                            <div key={bank._id}>
                              <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Bank Name
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {bank.bankName}
                                  </span>
                                </div>
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Account Number
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {bank.accountNumber}
                                  </span>
                                </div>
                              </div>
                              <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Account Name
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {bank.accountName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                          {!showCompanyBankEdit && (
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              label="Edit"
                              className="mt-10 bg-transparent border-[#0353A4] text-[#0353A4]"
                              icon={<Icon name={Icons.EditPencil} />}
                              iconPosition="left"
                              iconLabelSpacing={5}
                              onClick={() => setShowCompanyBankEdit(true)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </DropDown>
                )}
              </AnimatePresence>
            </div>
          </div>
          {/* Company Address */}
          <div className="px-12 mt-5">
            <div className="border-b">
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleAccordian('address', accordian.address)}
                className="flex items-center justify-between mb-8"
              >
                <span className="text-xl font-semibold text-black font-inter">
                  Company Address
                </span>
                <button
                  type="button"
                  className={`transform ${accordian.address ? 'rotate-180' : ''}`}
                >
                  <Icon name={Icons.CaretDown} />
                </button>
              </div>
              <AnimatePresence>
                {accordian.address && (
                  <DropDown>
                    <div>
                      {showCompanyAddressEdit ? (
                        <EditCompanyAddress
                          business={docs[0]?.businessID}
                          onSave={(values) => handleSubmit(values as FormDto)}
                        />
                      ) : (
                        <div className="pb-9">
                          {(docs[0]?.businessID?.addresses ?? []).map((address) => (
                            <div key={address._id}>
                              <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Address
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {address.address}
                                  </span>
                                </div>
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Country
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {address.country}
                                  </span>
                                </div>
                              </div>
                              <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    State
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {address.state}
                                  </span>
                                </div>
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Postal Code
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {address.postalCode}
                                  </span>
                                </div>
                              </div>
                              <div className="grid max-w-2xl grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium text-black/40 font-inter">
                                    Address Type
                                  </span>
                                  <span className="text-lg font-medium text-black font-inter">
                                    {address.addressType}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}

                          {!showCompanyAddressEdit && (
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              label="Edit"
                              className="mt-10 bg-transparent border-[#0353A4] text-[#0353A4]"
                              icon={<Icon name={Icons.EditPencil} />}
                              iconPosition="left"
                              iconLabelSpacing={5}
                              onClick={() => setShowCompanyAddressEdit(true)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </DropDown>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
