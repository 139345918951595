import { Icon, Icons } from '../Icon';

type Props = {
  fileName: string;
  removeFile?: () => void;
  canBeDelete?: boolean;
  label?: string;
  requiredIndicator?: boolean;
};

export default function UploadPreview({
  fileName,
  removeFile,
  canBeDelete = true,
  label,
  requiredIndicator,
}: Props) {
  return (
    <div className={canBeDelete ? 'mt-4.5' : ''}>
      <p>
        <span className="text-[#000000] font-medium text-sm font-inter">{label}</span>
        {requiredIndicator && <span className="text-[red] font-inter">*</span>}
      </p>
      <div>
        <div className="cursor-pointer p-4 py-6 bg-white border border-[#00000033] rounded-lg my-2 gap-3 flex items-center justify-between ">
          <div className="flex items-center gap-3">
            <Icon name={Icons.File} />
            <span className="text-sm font-medium font-inter">{fileName}</span>
          </div>
          {canBeDelete && (
            <div
              className="h-3 cursor-pointer"
              role="button"
              tabIndex={0}
              onClick={removeFile}
            >
              <Icon name={Icons.Delete} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
