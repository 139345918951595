import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  text: string;
  subText?: any;
  innerText?: any;
};

export default function Breadcrumb({ text, subText, innerText }: Props) {
  return (
    <ol
      className={`flex ${
        subText ? 'text-[#000000]' : 'text-[#0353A4]'
      } font-medium text-xs font-inter`}
    >
      <li>
        <div
          className={`flex ${
            subText ? 'text-[#0353A4]' : 'text-[#000]'
          } font-medium text-xs font-inter`}
        >
          <Link to={`/${text}`}>{text}</Link>
        </div>
      </li>
      <li className="before:content-['>'] before:px-1.5"></li>
      <li className={`${innerText ? 'text-[#000000]' : 'text-[#0353A4]'} `}>
        <div>{subText}</div>
      </li>
      {innerText && (
        <>
          <li className="before:content-['>'] before:px-1.5"></li>
          <li className={`${innerText ? 'text-[#0353A4]' : 'text-[#000000]'} `}>
            <div>{innerText}</div>
          </li>
        </>
      )}
    </ol>
  );
}
