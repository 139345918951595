import { AppDispatch } from './store-types';

export type LoginPayloadDto = {
  email: string;
  password: string;
  dispatch: AppDispatch;
  isAlreadyLogin?: boolean;
};

export type LoginResponseDto = {
  token: string;
};

export type LoginUser = {
  user: User;
  tokens: Tokens;
};

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImageURL: string;
  blocked: boolean;
  verifiedEmail: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export const initialUserValue = {
  user: {
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    profileImageURL: '',
    blocked: false,
    verifiedEmail: false,
    createdAt: '',
    updatedAt: '',
  },
  tokens: {
    accessToken: '',
    refreshToken: '',
  },
};

export type CreateAccountDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type EarlyAccessDto = {
  name: string;
  email: string;
  companyName: string;
  teamSize: string;
  companyWebsite: string;
  vendorCount: string;
  companyDescription: string;
};
