import { FaMinus, FaPlus } from 'react-icons/fa';

function Directors({ directoss, setDirectoss, emptyFields, disabled }: any) {
  const handleAddressChange = (event: any, index: any) => {
    const data: any = [...directoss];
    data[index][event.target.name] = event.target.value;
    setDirectoss(data);
  };
  const addAddressFields = () => {
    const object = {
      type: '',
      fullName: '',
      number: '',
    };
    setDirectoss([...directoss, object]);
  };
  const removeAddressFields = (index: any) => {
    const data = [...directoss];
    data.splice(index, 1);
    setDirectoss(data);
  };
  return (
    <div className="form-body">
      <div className="tag">
        <p>Directors Info</p>
      </div>
      {directoss.map((form: any, index: any) => (
        <div className="seti" key={index + 1}>
          <div className="card">
            <select
              name="type"
              value={form.type}
              required
              onChange={(event) => handleAddressChange(event, index)}
            >
              <option value="">-Select-</option>
              <option value={'NIN'}>National Identification Number (NIN)</option>
              <option value={'passportNO'}>Passport Number</option>
            </select>
          </div>
          <div className="card">
            <p>Fullname</p>
            <input
              type="text"
              id=""
              name="fullName"
              disabled={disabled}
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.fullName}
              className={
                emptyFields.includes(
                  'Fill at least one Director Information completely'
                ) && directoss[index].fullName === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className="card">
            <p>Director's {form.type === 'passportNO' ? 'Passport Number' : form.type}</p>
            <input
              type="text"
              id=""
              name="number"
              disabled={disabled}
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.number}
              className={
                emptyFields.includes(
                  'Fill at least one Director Information completely'
                ) && directoss[index].number === ''
                  ? 'active'
                  : ''
              }
            />
            {!disabled && (
              <div className="anothes">
                <div className="remove">
                  <FaMinus onClick={() => removeAddressFields(index)} />
                  <p onClick={() => removeAddressFields(index)}>Remove</p>
                </div>
                <div className="addx">
                  <FaPlus onClick={addAddressFields} />
                  <p onClick={addAddressFields}>Add another</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {directoss.length === 0 && (
        <>
          {!disabled && (
            <div className="seti">
              <div className="card" style={{ width: '100%' }}>
                <div className="anothes">
                  <div className="remove"></div>
                  <div className="addx">
                    <FaPlus onClick={addAddressFields} />
                    <p onClick={addAddressFields}>Add another</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Directors;
