import React from 'react';
import { TransactionDoc } from '../../../types/transaction.type';
import { InfoModal } from '../InfoModal';
import Avatar from '../../../Assests/avatar-1.png';
import Rating from '../../Rating';

type Props = {
  onCancel: () => void;
  transaction: TransactionDoc | null;
};

export default function RateDetails({ onCancel, transaction }: Props) {
  return (
    <InfoModal width="w-[40%]" className="h-fit " onClose={onCancel}>
      <div className="rounded-[20px] bg-white font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="mx-auto w-full">
            <p className="font-semibold text-xl font-inter text-black">
              Transaction Rating
            </p>
            <div className="mx-auto ">
              <div className="flex items-center mt-4 gap-5">
                <div className="relative flex-shrink-0">
                  <img
                    src={Avatar}
                    alt="avatar"
                    className="h-24 w-24 rounded-full object-cover"
                  />
                </div>
                <div className="mx-3 text-left ml-6">
                  <Rating rating={Number(transaction?.rating?.buyer.value) ?? '0'} />
                  <p className="max-w-[185px] truncate text-base text-black mt-3">
                    {transaction?.rating?.buyer.comment}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
