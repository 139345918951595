import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../Assests/2.png';
import Loader from '../../Assests/loader2.gif';
import { BsPatchCheckFill } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';
import axios, { AxiosError } from 'axios';

interface Props {
  notify: (type: string, message: string) => void;
}
interface ErrorResponse {
  errors: string[];
}

function Verificatuion({ notify }: Props) {
  const { email, token } = useParams();
  const [failed, setFailed] = useState('');
  const [loading, setLoading] = useState('loading');
  const navigate = useNavigate();
  const onSubmit = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL; // Get the base URL from the environment variable
      const response = await axios.get(`${baseUrl}/auth/activation/${email}/${token}`);
      if (response.data.success) {
        setLoading('success');
        setTimeout(() => {
          navigate('/auth/login');
        }, 1000);
      }
      notify('success', 'Account activated successfully');
      setLoading('success');
      setTimeout(() => {
        navigate('/auth/login');
      }, 1000);
    } catch (error) {
      setLoading('fail');
      const err = error as AxiosError<ErrorResponse>;
      if (axios.isAxiosError(err) && err.response) {
        const errorMessages = err.response.data.errors || ['An error occurred.'];
        setFailed(errorMessages.join(','));
      } else {
        setFailed('An unexpected error occurred.');
      }
    }
  };
  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <div className="verification">
      <div className="cover">
        <div className="top">
          <img src={Logo} alt="" />
        </div>
        <div className="rest">
          {loading === 'loading' && (
            <div className="center">
              <img src={Loader} alt="" />
              <h1>Activating..</h1>
            </div>
          )}
          {loading === 'success' && (
            <div className="center">
              <BsPatchCheckFill className="suc" />
              <h1 className="suc">Account Activated, Redirecting..</h1>
            </div>
          )}
          {loading === 'fail' && (
            <div className="center">
              <ImCancelCircle className="fail" />
              <h1 className="suc">{failed}, Redirecting...</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verificatuion;
