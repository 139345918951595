import React from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { determinePathName } from '../../../../utils';

const items = [
  { id: 1, path: '/settings', name: 'General' },
  { id: 2, path: 'compliance', name: 'Compliance' },
  { id: 3, path: 'department', name: 'Department' },
  { id: 4, path: 'collaborators', name: 'Collaborators' },
  { id: 5, path: 'approval', name: 'Approval' },
  { id: 6, path: 'account', name: 'Subscription' },
];

export default function Settings() {
  const { pathname } = useLocation();

  const renderTabNav = () => {
    return items.map((item) => (
      <NavLink
        key={item.id}
        to={item.path}
        className={`font-medium font-inter p-3.5 py-2.5 block rounded-md text-center ${
          determinePathName(pathname) === item.path
            ? 'bg-[#B9D6F2] text-[#0353A4]'
            : 'text-black/60 text-sm'
        }`}
      >
        <span className="block mr-3 md:text-base text-xs">{item.name}</span>
      </NavLink>
    ));
  };

  return (
    <div>
      <Breadcrumb text="Settings" />
      <div className="panel mt-5 rounded-[20px] bg-white">
        <div className="flex items-centers justify-center">
          <div className="flex flex-col gap-5 border-r p-5 pt-12">{renderTabNav()}</div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
