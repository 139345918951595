import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createAccount,
  createPassword,
  earlyAccess,
  forgotPassword,
  resendVerificationEmail,
  resetPassword,
  verifyToken,
} from '../../service/authentication';
import { toast } from 'react-toastify';
import { CreateAccountDto } from '../../types/user.type';

export const useForgetPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (email: string): Promise<void> => {
      return forgotPassword(email);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['forgot-password']);
      },
      onError: (error: Error) => {
        toast.error(error.message ?? 'An error has occurred');
      },
    }
  );
};

export const useCreateUserAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: CreateAccountDto): Promise<void> => {
      return createAccount(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-account']);
      },
      onError: (error: Error) => {
        toast.error(error.message ? error.message : 'Sorry!, something went wrong');
      },
    }
  );
};

export const useCreatePassword = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: any): Promise<void> => {
      return createPassword(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-password']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useResendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (): Promise<void> => {
      return resendVerificationEmail();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['resend-email']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useResetPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: any): Promise<void> => {
      return resetPassword(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['reset-password']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useEarlyAccess = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: any): Promise<void> => {
      return earlyAccess(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['early-access']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useVerifyToken = ({ token }: { token: string }) => {
  return useQuery(['audits'], () => verifyToken(token), {
    refetchOnWindowFocus: false,
    enabled: !!token,
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
};
