import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

type IProps = {
  setIsLoading: (arg: boolean) => void;
  width?: number;
};

const CalendlyBooking = ({ setIsLoading, width = 600 }: IProps) => {
  useCalendlyEventListener({
    onEventTypeViewed: () => setIsLoading(false),
  });

  return (
    <div className="relative bg-white z-[999] h-full w-full">
      <InlineWidget
        styles={{
          backgroundColor: 'white',
          minHeight: '100%',
          height: width,
        }}
        url="https://calendly.com/paytton-support/demo"
      />
    </div>
  );
};

export default CalendlyBooking;
