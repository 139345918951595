import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Icon, Icons } from '../Icon';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const fileTypes = ['JPG', 'PNG', 'PDF', 'DOC'];

type Props = {
  children?: React.ReactNode;
  label: string;
  getFile?: (file: File) => void;
  getMultipleFile?: (files: File[]) => void;
  error?: string;
  isMultiple?: boolean;
  value?: string | null;
  name?: string;
  fileName?: string;
  acceptFile?: string | string[] | undefined;
  requiredIndicator?: boolean;
};

const FileUpload: React.FC<Props> = ({ getFile, label, error, requiredIndicator }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');

  const handleChange = (file: any) => {
    setPreviewUrl(file);
    getFile && getFile(file);
    setUploadedFileName(file.name);
  };

  return (
    <div className="mt-5 ">
      <p>
        <span className="text-[#000000] font-medium text-sm font-inter">{label}</span>
        {requiredIndicator && (
          <span className="text-[red] font-medium ml-1 text-sm font-inter">*</span>
        )}
      </p>
      <div className="px-14 bg-white border border-dashed border-[#00000033] rounded-lg my-2 gap-3 py-4 flex items-center justify-center ">
        {previewUrl ? (
          <div className="flex flex-col items-center">
            <img
              src={previewUrl ? previewUrl : ''}
              alt="Preview"
              className="max-w-full mt-2 max-h-40"
            />
            <span className="mt-2 text-xs">{uploadedFileName}</span>
          </div>
        ) : (
          <FileUploader
            label="Drag file here to upload or choose file"
            uploadedLabel="Upload Successful"
            hoverTitle="Drop file here"
            handleChange={handleChange}
            name="file"
            onTypeError={(err: any) => {
              const error = err as AxiosError<Error>;
              toast.error(error.message);
            }}
            types={fileTypes}
          >
            <div className="flex items-center gap-3 justify-between">
              <div className="bg-[#022C75] rounded-full p-4">
                <Icon name={Icons.Upload} />
              </div>
              {previewUrl ? (
                <div className="flex flex-col items-center">
                  <img
                    src={previewUrl ? previewUrl : ''}
                    alt="Preview"
                    className="max-w-full mt-2 max-h-40"
                  />
                  <span className="mt-2 text-xs">{uploadedFileName}</span>
                </div>
              ) : (
                <label className="text-sm font-normal cursor-pointer" htmlFor="upload">
                  <span className="text-[#022C75]">Drag file here</span> to upload or
                  choose file
                </label>
              )}
            </div>
          </FileUploader>
        )}
      </div>
      {error && <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>}
    </div>
  );
};

export default FileUpload;
