import clsx from 'clsx';
import React from 'react';
import { Icon, Icons } from '../Icon';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'outline' | 'transparent' | 'custom';
  size?: 'lg' | 'sm' | 'md' | 'custom';
  label?: string;
  onClick?: (arg: any) => void;
  type?: 'button' | 'submit';
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  capitalized?: boolean;
  className?: any;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  iconLabelSpacing?: number;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  label,
  onClick,
  type = 'button',
  loading = false,
  disabled = false,
  capitalized,
  className,
  size,
  loadingText,
  icon,
  iconPosition,
  iconLabelSpacing,
}) => {
  return (
    <button
      className={clsx({
        'flex text-sm font-medium bg-[#061A40] rounded-lg justify-center items-center':
          true,
        'px-5 py-3 text-base w-52': size === 'md',
        'px-5 py-2.5 text-sm w-24': size === 'sm',
        'px-6 py-3.5 text-base w-full': size === 'lg',
        'bg-[#061A40] text-white border border-gray-00': variant === 'primary',
        'border border-[#061A40] bg-[#061A401A] text-[#061A40]': variant === 'outline',
        'opacity-50 hover:none cursor-not-allowed bg-[#5b5a5a2b]': disabled,
        'bg-transparent outline-none': variant === 'transparent',
        '': variant === 'custom',
        capitalize: capitalized,
        [className]: className,
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {loading && <Icon name={Icons.Loading} width={16} height={16} />}
      {icon && iconPosition === 'left' && !loading && (
        <>
          {icon}
          <div style={{ width: `${iconLabelSpacing}px` }} />
        </>
      )}
      {!loading ? label : !loadingText ? 'Loading...' : `${loadingText}...`}
      {icon && iconPosition === 'right' && !loading && (
        <>
          <div style={{ width: `${iconLabelSpacing}px` }} />
          {icon}
        </>
      )}
    </button>
  );
};

export default Button;
