import { AxiosError } from 'axios';
import { baseService, errorHandler, getToken } from './';
import { TransactionType } from '../types/transaction.type';

export const getTransactions = async (businessId: any): Promise<TransactionType> => {
  try {
    const response = await baseService.get(`transaction/${businessId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const rateTransaction = async (
  businessId: string,
  transactionId: string,
  payload: any
) => {
  try {
    const response = await baseService.patch(
      `transaction/${businessId}/${transactionId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
