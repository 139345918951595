import { PaystackSuccessDto } from '../types/payment.type';
import { PaystackButton } from 'react-paystack';
import { Icon, Icons } from './Icon';

type Props = {
  isProcessing: boolean;
  onPaymentSuccess: (paystackSuccessDto: PaystackSuccessDto) => void;
  onPaymentClose: () => void;
  email: string;
  amount: number;
  className: string;
  text: string;
};

export default function Paystack({
  isProcessing,
  onPaymentSuccess,
  onPaymentClose,
  email,
  amount,
  className,
  text,
}: Props) {
  return (
    <div>
      {isProcessing ? (
        <>
          <Icon name={Icons.Loading} width={16} height={16} />
        </>
      ) : (
        <PaystackButton
          onSuccess={onPaymentSuccess}
          onClose={onPaymentClose}
          email={email}
          amount={amount * 100}
          publicKey={process.env.REACT_APP_PAYSTACK_PUBLIC_KEY as string}
          className={className}
          text={text}
        />
      )}
    </div>
  );
}
