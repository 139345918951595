import { AxiosError } from 'axios';
import { axiosCalls } from './_axios';
import { errorHandler, getToken } from '../service';
import axios from 'axios';
export const getCostOfSeats = async (businessId: string, seat: string) => {
  try {
    const response = await axiosCalls(
      `/subscription/${businessId}/cost?seat=${seat}`,
      'GET',
      null,
      getToken()
    );

    return response.data.cost;
  } catch (error) {
    const err = error as AxiosError<Error>;

    errorHandler(err);
  }
};

export const disableRole = async (businessId: string, id: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/role/${businessId}/archive`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: id,
      },
    });
    return response;
  } catch (error) {
    const err = error as AxiosError<Error>;
    errorHandler(err);
  }
};
export const archiveEmployeeDetails = async (businessId: string, userId: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/business/${businessId}/members/${userId}/block`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: userId,
      },
    });
    return response;
  } catch (err) {
    const error = err as AxiosError<Error>;
    errorHandler(error);
  }
};

export const reActivateEmployeeDetails = async (businessId: string, userId: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/business/${businessId}/members/${userId}/unblock`,
      method: 'patch',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: userId,
      },
    });
    return response;
  } catch (err) {
    const error = err as AxiosError<Error>;

    errorHandler(error);
  }
};

export const archiveADept = async (businessId: string, id: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/department/${businessId}/archive`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: id,
      },
    });

    if (response.data && response.status === 201) {
      return response;
    }
  } catch (err) {
    const error = err as AxiosError<Error>;
    errorHandler(error);
  }
};
