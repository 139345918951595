import React, { useEffect, useState } from 'react';
import Button from '../Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../Form/TextField';
import {
  useCreateRole,
  useGetPermissionList,
  useUpdateRole,
} from '../../hooks/queries-and-mutations/settings';
import { InfoModal } from './InfoModal';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import { RoleDoc } from '../../types/setting.type';
import { useAppSelector } from '../../store/hooks';
import { selectBusiness } from '../../selectors/business-selector';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  businessId: string;
  selectedRole: RoleDoc | null;
};

export default function AddNewRoleModal({
  onCancel,
  onSuccess,
  businessId,
  selectedRole,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isSuccess, isLoading } = useGetPermissionList();
  const { mutate, isSuccess: isSaved, isError } = useCreateRole();
  const {
    mutate: update,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateRole();
  const { docs } = useAppSelector(selectBusiness);

  const formik = useFormik({
    initialValues: {
      name: selectedRole?.name ?? '',
      permissions: selectedRole?.permissions ?? [],
      id: selectedRole?._id ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Role is required').nullable(),
      permissions: Yup.array()
        .min(1, 'At least one role permission is required')
        .nullable()
        .required(),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setFieldTouched }) => {
      for (const key in values) {
        setFieldTouched(key, true);
      }
      setIsSubmitting(true);
      if (selectedRole) {
        update({
          name: values.name,
          permissions: values.permissions,
          businessId,
          id: values.id,
        });
      } else {
        mutate({ name: values.name, permissions: values.permissions, businessId });
      }
    },
  });

  const handleCheckboxChange = (value: string) => {
    if (formik.values.permissions.includes(value as never)) {
      formik.setFieldValue(
        'permissions',
        formik.values.permissions.filter((item) => item !== value)
      );
    } else {
      formik.setFieldValue('permissions', [...formik.values.permissions, value]);
    }
  };

  useEffect(() => {
    if (isSaved && !isError) {
      toast.success('Role successfully created');
      setIsSubmitting(false);
      formik.setSubmitting(false);
      onSuccess();
    } else if (isError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSaved]);

  useEffect(() => {
    if (isUpdated && !isUpdateError) {
      toast.success('Role successfully updated');
      setIsSubmitting(false);
      formik.setSubmitting(false);
      onSuccess();
    } else if (isUpdateError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateError, isUpdated]);

  return (
    <InfoModal width="w-[45%]" className="h-fit " onClose={onCancel}>
      <div className="mr-12 rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="pb-2 text-left max-w-sm border-b">
            <p className="text-xl font-medium font-inter text-black">
              {selectedRole ? 'Add New Role' : 'Edit Role'}
            </p>
            <p className="text-sm font-normal font-inter text-black/40">
              {selectedRole
                ? 'Create a new role for your organization'
                : 'Edit a role for your organization'}
            </p>
          </div>

          <div className="mx-auto w-full mt-7">
            <form className="space-y-0.5" onSubmit={formik.handleSubmit}>
              <TextField
                name="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Role"
                className="bg-white"
                error={formik.touched.name ? formik.errors.name : ''}
              />

              <div className="flex flex-col border-b border-black/5 pt-5 pb-3">
                <div className="flex flex-col gap1">
                  <p className="text-sm font-medium font-inter text-black">
                    Role Permission
                  </p>
                  <p className="text-xs font-normal font-inter text-black/40">
                    Select permissions for the new role
                  </p>
                </div>
              </div>
              {isLoading && <Loader />}
              {!isLoading && isSuccess && (
                <div className="py-6">
                  {data &&
                    (data ?? [])?.map((item) => (
                      <div className="flex items-center justify-between border-b py-4">
                        <p className="text-black font-normal text-sm">
                          {item.permission
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char: any) => char.toUpperCase())}
                        </p>
                        <input
                          type="checkbox"
                          className="form-checkbox text-dark"
                          onChange={() => handleCheckboxChange(item.permission)}
                          checked={formik.values.permissions.includes(
                            item.permission as never
                          )}
                          style={{ border: '2px #C9C9C9 solid' }}
                        />
                      </div>
                    ))}
                </div>
              )}

              <Button
                type="submit"
                variant="primary"
                size="lg"
                label={selectedRole ? 'Edit' : 'Add'}
                loading={isSubmitting || formik.isSubmitting}
                disabled={
                  isSubmitting ||
                  formik.isSubmitting ||
                  docs[0].businessID.status === 'pending'
                }
              />
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
