import React, { useCallback } from 'react';
import { Icon, Icons } from '../Icon';
import { useDropzone } from 'react-dropzone';

type Props = {
  label: string;
  getFile?: (file: File) => void;
  getMultipleFile?: (files: File[]) => void;
  isMultiple?: boolean;
  error?: string;
  fileName?: string;
  acceptFile?: string[];
};

export default function UploadFile({
  getFile,
  label,
  error,
  fileName,
  acceptFile,
  getMultipleFile,
  isMultiple = false,
}: Props) {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        return null;
      }

      if (isMultiple && getMultipleFile) {
        const newFiles: File[] = [];
        acceptedFiles.forEach((file, index) => {
          const iteration = index + 1;
          const newName = fileName + '_' + iteration + '.' + file.name.split('.').pop(); // create new file name
          const newFile = new File([file], newName, { type: file.type }); // create new file object with updated name
          newFiles.push(newFile);
        });
        return getMultipleFile(newFiles);
      } else if (!isMultiple && getFile) {
        const file = acceptedFiles[0];
        const newName = fileName + '.' + file.name.split('.').pop(); // create new file name
        const newFile = new File([file], newName, { type: file.type }); // create new file object with updated name
        return getFile(newFile);
      }
    },
    [fileName, getFile, getMultipleFile, isMultiple]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': acceptFile?.length
        ? acceptFile
        : ['.jpeg', '.png', '.jpg', '.pdf', '.doc'],
    },
    // maxFiles: 1
  });

  return (
    <div className="mt-5" {...getRootProps()}>
      <span className="text-[#000000] font-medium text-sm font-inter">{label}</span>
      <div className="px-14 bg-white border border-dashed border-[#00000033] rounded-lg my-2 gap-3 py-4 flex items-center justify-center ">
        <div className="bg-[#022C75] rounded-full p-4">
          <Icon name={Icons.Upload} />
        </div>

        <label className="font-normal text-sm cursor-pointer" htmlFor="upload">
          <span className="text-[#022C75]">Drag file here to</span> upload or choose file
        </label>
        <input type="file" hidden id="upload" {...getInputProps()} accept=".pdf" />
      </div>
      {error && <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>}
    </div>
  );
}
