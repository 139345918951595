import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTransactions, rateTransaction } from '../../service/transaction';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../thunks/account-thunk';

export const useGetTransactions = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(['transaction'], () => getTransactions(businessId), {
    refetchOnWindowFocus: false,
    enabled: !!businessId,
    onError: (error: Error) => {
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useRateTransaction = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      transactionId,
      payload,
    }: {
      businessId: string;
      transactionId: string;
      payload: any;
    }): Promise<void> => {
      return rateTransaction(businessId, transactionId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rate-transaction']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};
