import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createProduct, getProducts, updateProduct } from '../../service/product';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../thunks/account-thunk';

export const useGetProducts = ({
  enabled,
  page,
  businessId,
}: {
  enabled: boolean;
  page: number;
  businessId: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(['business'], () => getProducts(businessId, page), {
    refetchOnWindowFocus: false,
    enabled: enabled,
    onError: (error: Error) => {
      toast.error(error.message);
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, payload }: { businessId: string; payload: any }): Promise<void> => {
      return createProduct(businessId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-product']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      productId,
      payload,
    }: {
      businessId: string;
      productId: string;
      payload: any;
    }): Promise<void> => {
      return updateProduct(businessId, productId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-product']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};
