import { AxiosError } from 'axios';
import { baseService, errorHandler, getToken } from '.';
import { CreateAccountDto, EarlyAccessDto } from '../types/user.type';

export const forgotPassword = async (email: string) => {
  try {
    const response = await baseService.post(`/auth/password/reset/${email}`);
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createAccount = async (payload: CreateAccountDto) => {
  try {
    const response = await baseService.post('/auth/user/create', payload);
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createPassword = async (payload: any) => {
  try {
    const response = await baseService.post('/auth/password/forgot', payload);
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const resendVerificationEmail = async () => {
  try {
    const response = await baseService.post('auth/activation/resend', null, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const resetPassword = async (payload: any) => {
  try {
    const response = await baseService.post('/auth/password/reset', payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const earlyAccess = async (payload: EarlyAccessDto) => {
  try {
    const response = await baseService.post('/business/earlyaccess', payload);
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const verifyToken = async (token: string) => {
  try {
    const response = await baseService.get(`/business/token-verification?token=${token}`);
    if (response.data) {
      return (response.data ?? {}).data;
    }
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
