import { useState } from 'react';
import { InfoModal } from '../InfoModal';
import Button from '../../Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
};

export default function DelinePaymentModal({ onCancel }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object({
      reason: Yup.string()
        .required('Tell us why you want to decline this invoice')
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      console.log(
        '🚀 ~ file: AddAccountModal.tsx:39 ~ AddAccountModal ~ values:',
        values
      );
      try {
        // dispatch(login({ email: email.trim(), password: password.trim() }));
      } catch (error) {
        console.log('Error', error);
      }
    },
  });

  return (
    <InfoModal width="w-[30%]" className="h-fit " onClose={onCancel}>
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          <div className="max-w-xs p-5 text-center">
            <p className="text-xl font-medium text-black font-inter">
              Are you sure you want to decline this payment ?
            </p>
          </div>

          <form className="w-full pt-6" onSubmit={formik.handleSubmit}>
            <textarea
              name="reason"
              rows={5}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              placeholder="Tell us why you want to decline this payment"
              className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.reason && formik.errors.reason
                          ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
              defaultValue={formik.values.reason}
            />
            {formik.errors.reason && (
              <p className="mt-2 text-xs text-red-600 capitalize">
                {formik.errors.reason}
              </p>
            )}
            <div className="flex items-center w-full gap-5 mt-5">
              <Button
                type="button"
                variant="primary"
                size="lg"
                label="Cancel"
                className="my-5"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
                onClick={onCancel}
              />
              <Button
                type="submit"
                variant="primary"
                size="lg"
                label="Decline"
                className="my-5 px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </InfoModal>
  );
}
