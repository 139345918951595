import React from 'react';
import { Icon, Icons } from '../../Components/Icon';
import { useNavigate } from 'react-router-dom';

export default function PricingMobile() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="relatives md:p-20 md:pb-52 p-10 bg-[#0B58A71C]">
        <div className="">
          <div className="flex flex-col items-center justify-center mb-14 text-center">
            <p className="font-medium text-4xl text-black">
              Supercharge your source-to-pay efficiency
            </p>
            <span className="text-black text-lg font-normal">
              Get early access and see how Paytton can help you save time, money, and
              improve compliance
            </span>
          </div>
          <div className="relatives mt-10">
            <div className="py-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
              <div className="sw-[78%]">
                <div className="relatives bg-white rounded-[20px] h-full">
                  <div className="p-6">
                    <Icon name={Icons.People} />
                  </div>
                  <div className="px-6 pb-6">
                    <h3 className="text-lg font-medium text-gray-800 mb-0.5">
                      Efficiency and
                      <br />
                      Seamless Collaboration
                    </h3>
                    <p className="text-gray-500 mt-3 font-normal text-sm">
                      Empower your procurement and finance teams to work in perfect
                      harmony with Paytton's intuitive collaboration features. Say goodbye
                      to silos and embrace unified workflows.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sw-[78%]">
                <div className="relatives bg-white rounded-[20px] h-full">
                  <div className="p-6">
                    <Icon name={Icons.HandCoin} />
                  </div>
                  <div className="px-6 pb-6">
                    <h3 className="text-lg font-medium text-gray-800 mb-0.5">
                      Savings and Optimized
                      <br />
                      Financial Decisions
                    </h3>
                    <p className="text-gray-500 mt-3 font-normal text-sm">
                      Unlock significant cost savings and optimize financial decisions
                      with real-time spending visibility. Make informed choices that
                      impact your bottom line positively.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sw-[78%]">
                <div className="relatives bg-white rounded-[20px] h-full">
                  <div className="p-6">
                    <Icon name={Icons.Growth} />
                  </div>
                  <div className="px-6 pb-6">
                    <h3 className="text-lg font-medium text-gray-800 mb-0.5">
                      Enhanced Compliance
                      <br /> and Risk Mitigation
                    </h3>
                    <p className="text-gray-500 mt-3 font-normal text-sm">
                      Centralize vendor data and approvals within Paytton, reducing the
                      risk of errors and ensuring compliance with regulations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 absolutes grid md:grid-cols-2 grid-cols-1 gap-6 items-center px-14 pt-10 bg-[#0353A463] rounded-2xl">
        <div className="sw-[35%] flex flex-col mt-8">
          <div className="space-y-3">
            <p className="font-semibold text-xl text-white">
              One tool for your source-to-pay operations
            </p>
            <p className="text-lg font-normal text-white">Free for teams to try.</p>
          </div>
          <button
            onClick={() => navigate('/auth/create-account')}
            className="rounded-[9.79px] w-[10rem] text-base font-medium p-3 mt-5 bg-[#061A40] text-white"
          >
            Get started
          </button>
        </div>
        <div className="bg-white/50 rounded-tl-xl rounded-tr-xl relative overflow-hidden shadow-md">
          <div className="flex flex-col h-full p-6">
            <div className="shrink">
              <h5 className="text-xl font-semibold mb-2 text-black">Standard</h5>
              <div className="my-3">
                <h3 className="flex items-center gap-2 font-bold text-2xl text-black">
                  <span>$5</span>
                  <span className="text-lg font-medium">/ seat</span>
                  <span className="text-lg font-medium">/ month</span>
                </h3>
              </div>
            </div>
            <div className="mt-3">
              <p className="text-sm text-gray-700 font-medium">Enjoy these features</p>
            </div>
            <ul className="grow mt-5">
              <li className="flex items-center gap-2 mb-4 font-normal text-sm">
                <Icon name={Icons.Mark} />
                <span className="ml-2">Vendor management </span>
              </li>
              <li className="flex items-center gap-2 mb-4 font-normal text-sm">
                <Icon name={Icons.Mark} />
                <span className="ml-2">Invoicing</span>
              </li>
              <li className="flex items-center gap-2 mb-4 font-normal text-sm">
                <Icon name={Icons.Mark} />
                <span className="ml-2">Payments</span>
              </li>
            </ul>
            <div className="shrink mt-3 font-medium text-sm text-black/75">
              <p>You get 14 days to try it out, no commitment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
