import slide1 from '../Assests/slide-1.png';
import slide2 from '../Assests/slide-2.png';
import slide3 from '../Assests/slide-3.png';
import slide4 from '../Assests/slide-4.png';
import slide6 from '../Assests/slide-6.png';

export const faqs = [
  {
    id: 1,
    name: `What is Paytton, and how does it benefit procurement and finance teams?`,
    desc: `Paytton is a comprehensive collaboration platform designed exclusively for procurement and finance teams. It streamlines the source-to-pay process, automates manual tasks, and provides real-time insights to optimize spending, enhance efficiency, and ensure compliance.`,
  },
  {
    id: 2,
    name: `Can Paytton automate vendor onboarding and payment approvals?`,
    desc: `Yes, Paytton's advanced automation capabilities enable seamless vendor onboarding and automated payment approvals, saving your teams valuable time and reducing the risk of errors.`,
  },
  {
    id: 3,
    name: `How does Paytton improve communication and collaboration between procurement and finance teams?`,
    desc: `Paytton unifies procurement and finance operations within one platform, fostering seamless communication and collaboration. Real-time updates and shared data empower teams to work together efficiently and harmoniously.`,
  },
  {
    id: 4,
    name: `Does Paytton offer real-time spending visibility and financial insights?`,
    desc: `Yes, Paytton provides real-time visibility into spending, invoice statuses, and payments. With actionable data at your fingertips, your teams can make informed financial decisions and identify cost-saving opportunities.`,
  },
  {
    id: 5,
    name: `How does Paytton mitigate compliance and risk management challenges?`,
    desc: `Paytton centralizes vendor data and approvals, ensuring compliance with regulations and reducing the risk of compliance violations. By streamlining the process, Paytton minimizes the potential for errors.`,
  },
  {
    id: 6,
    name: `Can Paytton help in managing vendor relationships and performance?`,
    desc: `Yes, Paytton's vendor performance tracking allows you to evaluate vendor relationships, identify top performers, and optimize partnerships for enhanced supplier management.`,
  },
  {
    id: 7,
    name: `Can Paytton help with late payment alerts and timely payments?`,
    desc: `Yes, Paytton provides timely alerts for late payments, allowing your teams to take proactive actions to manage outstanding invoices and ensure on-time payments.`,
  },
  {
    id: 8,
    name: `How does Paytton simplify invoicing for procurement and finance teams?`,
    desc: `Paytton allows you to send and receive invoices effortlessly, boosting cash flow and improving invoice accuracy for seamless financial operations.`,
  },
  {
    id: 9,
    name: `Does Paytton offer a guarantee or free trial for early adopters?`,
    desc: `Yes, as an early adopter, you can try Paytton free for 14 days with no credit card required. Experience the power of Paytton firsthand and witness the positive impact on your source-to-pay process.`,
  },
  {
    id: 10,
    name: `Is Paytton suitable for businesses of all sizes?`,
    desc: `Yes, Paytton is designed to cater to the needs of businesses of all sizes, from small enterprises to large corporations. Its flexible features can adapt to your specific requirements.`,
  },
  {
    id: 11,
    name: `How secure is Paytton in handling sensitive financial data?`,
    desc: `Paytton prioritizes data security and employs industry-standard encryption and security measures to safeguard sensitive financial information.`,
  },
  {
    id: 12,
    name: `What kind of customer support does Paytton provide?`,
    desc: `Paytton offers dedicated customer support to assist you throughout your journey with our platform. Our team is ready to answer your queries and provide prompt assistance whenever you need it.`,
  },
  {
    id: 13,
    name: `How can I become an early adopter of Paytton?`,
    desc: `To become an early adopter of Paytton and revolutionize your source-to-pay process, simply sign up for our exclusive early adopter program on our website. Reserve your spot today and unlock the true potential of Paytton for your procurement and finance teams!`,
  },
];

export const slides = [
  {
    title: `Onboard new vendors <br />in half the time`,
    image: slide1,
    bodyClass: 'bg-[#D2E5F7]',
    textClass: 'text-black text-sm',
    imageClass: 'lg:px-16 md:px-16 px-4',
  },
  {
    title: 'Eliminate duplicate <br />payments',
    image: slide2,
    bodyClass: 'bg-[#0B58A7]',
    textClass: 'text-white text-sm',
    imageClass: 'lg:pl-16 md:pl-16 pl-8',
  },
  {
    title: 'Automate invoice matching, saving<br /> you hours of manual work',
    image: slide3,
    bodyClass: 'bg-white',
    textClass: 'text-black text-sm',
    imageClass: 'lg:px-16 md:px-16 px-4',
  },
  {
    title: 'Track your cash flow and manage<br /> your budget more effectively',
    image: slide4,
    bodyClass: 'bg-[#D2E5F7]',
    textClass: 'text-black text-sm',
    imageClass: 'lg:px-16 md:px-16 px-4',
  },
  {
    title:
      'Receive prompt alerts for late payments,<br /> so you can take action to collect',
    image: slide6,
    bodyClass: 'bg-[#0B58A7]',
    textClass: 'text-white text-sm',
    imageClass: 'lg:px-16 md:px-16 px-4',
  },
];
