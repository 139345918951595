import React from 'react';

type Props = {
  itemsPerPage: any;
  currentPage: any;
  totalCount: number;
};

const ShowCount: React.FC<Props> = ({ itemsPerPage, currentPage, totalCount }) => {
  if (totalCount > 0) {
    return (
      <p>
        {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
          currentPage * itemsPerPage,
          totalCount
        )} of ${totalCount}`}
      </p>
    );
  } else {
    return <div></div>;
  }
};

export default ShowCount;
