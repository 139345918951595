import { ReactComponent as Left } from '../../../Assests/left.svg';
import { ReactComponent as Right } from '../../../Assests/right.svg';
import Loader from '../../../Assests/loader.gif';
function ButtonDir({ stage, previousBtn, forwardBtn, formLoading, handleSubmit }: any) {
  return (
    <div className="buttons">
      {formLoading ? (
        <div>
          <img src={Loader} alt="" style={{ width: '100px' }} />
        </div>
      ) : (
        <>
          <button onClick={previousBtn}>
            <Left />
            <p>Previous</p>
          </button>
          <button
            onClick={() => {
              if (stage === 'preview') {
                handleSubmit();
              } else {
                forwardBtn();
              }
            }}
          >
            <p>
              {stage === 'preview'
                ? 'Submit'
                : stage === 'kyb'
                ? 'Save & Preview'
                : 'Save & Continue'}
            </p>
            {stage === 'preview' ? '' : <Right />}
          </button>
        </>
      )}
    </div>
  );
}

export default ButtonDir;
