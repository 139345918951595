import { clsx } from 'clsx';
import React from 'react';
import { Icon, Icons } from '../Icon';

type Props = {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string | null;
  placeholder: string;
  name: string;
  size?: 'half' | 'sm' | 'md' | 'lg' | 'xl';
  className?: any;
};

export default function SearchInput({
  onChange,
  onBlur,
  value,
  placeholder,
  className,
  size,
  name,
}: Props) {
  return (
    <div className="relative hidden w-full md:flex">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <Icon name={Icons.Search} />
      </div>
      <input
        type="search"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ''}
        className={clsx({
          'block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-sm text-gray-900 focus:ring-[#FF6600] ':
            true,
          'p-6': size === 'xl',
          'py-3.5': size === 'lg',
          'p-2.5 text-sm': size === 'md',
          'p-2 text-xs': size === 'sm',
          [className]: className,
        })}
        placeholder={placeholder}
      />
    </div>
  );
}
