import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Contact from './Contact';
import KYB from './KYB';
import Preview from './Preview';
import { ContactDto, FormDto, KYBDto } from '../../../types/business.type';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setOnboardingForm } from '../../../reducers/onboarding.reducer';
import { selectOnboarding } from '../../../selectors/onboarding-selector';

export default function Onboarding() {
  const business = useAppSelector(selectOnboarding);
  const [form, setForm] = useState<FormDto | null>(business || null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stage: currentStage } = useParams<{ stage: string }>();

  useEffect(() => {
    if (!currentStage) {
      navigate('/onboarding/contact');
    }
  }, [currentStage, navigate]);

  const handleSubmit = (values: FormDto) => {
    dispatch(setOnboardingForm(values));
    setForm(values);
    if (currentStage === 'contact') {
      navigate('/onboarding/kyb');
    } else if (currentStage === 'kyb') {
      navigate('/onboarding/preview');
    }
  };

  const renderComponent = useCallback(() => {
    if (currentStage === 'preview') {
      return <Preview />;
    } else if (currentStage === 'kyb') {
      return (
        <KYB
          values={form}
          onSubmit={(values: KYBDto) => handleSubmit({ ...form, ...values } as FormDto)}
        />
      );
    } else {
      return (
        <Contact
          values={form}
          onSubmit={(values: ContactDto) =>
            handleSubmit({ ...form, ...values } as FormDto)
          }
        />
      );
    }
  }, [form, currentStage]);

  return <>{renderComponent()}</>;
}
