import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../style/index.css';
import '../style/style.css';
import Step from './Step';

export default function OnBoardingLayout() {
  const { pathname } = useLocation();

  const contactSteps = [
    { title: 'Company Contact', key: 'contact', id: 1, hasNext: true },
    { title: 'Company Address', key: 'address', id: 2, hasNext: false },
  ];

  const kbySteps = [
    { title: 'Document Upload', key: 'contact', id: 1, hasNext: true },
    { title: 'Director Information', key: 'director', id: 2, hasNext: true },
    { title: 'Representative Information', key: 'representative', id: 3, hasNext: true },
    { title: 'Bank Account', key: 'bank', id: 4, hasNext: false },
  ];

  const details = [{ title: 'Detail Preview', key: 'preview', id: 1, hasNext: false }];

  const determineStep = useMemo(() => {
    if (pathname.includes('contact')) {
      return contactSteps;
    } else if (pathname.includes('kyb')) {
      return kbySteps;
    } else {
      return details;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="main-container min-h-screen text-black">
      <nav className="sidebar fixed top-0 bottom-0 z-50 h-full min-h-screen w-[337px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300 bg-[#0353A4]">
        <div className="h-full bg-[#0353A4] text-white">
          <ul className="mt-14 perfect-scrollbar relative h-[calc(100vh-80px)] space-y-0.5 overflow-y-auto overflow-x-hidden p-4 py-0 font-semibold">
            <div className="relative ms-6 mt-9 text-white font-normal text-sm">
              <Step path={pathname} steps={determineStep} />
            </div>
          </ul>
        </div>
      </nav>
      {/* end sidebar section */}
      <div className="main-content flex flex-col min-h-screen">
        <div className="animate__animated p-10 md:p-20 md:px-36 lg:p-20 lg:px-36">
          {/* start main content section */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
