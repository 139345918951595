import { AxiosError } from 'axios';
import { baseService, errorHandler, getToken } from '.';
import { ProductType } from '../types/product.type';

export const getProducts = async (
  businessId: any,
  page: any,
  limit = 10
): Promise<ProductType> => {
  try {
    const response = await baseService.get(
      `/product/${businessId}/fetch?limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createProduct = async (businessId: any, payload: any): Promise<void> => {
  try {
    const response = await baseService.post(`/product/${businessId}/create`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateProduct = async (
  businessId: string,
  productId: string,
  payload: any
): Promise<void> => {
  try {
    const response = await baseService.put(
      `/product/${businessId}/update/${productId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const deleteProduct = async (
  businessId: string,
  productId: string
): Promise<void> => {
  try {
    const response = await baseService.delete(
      `/product/${businessId}/delete/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
