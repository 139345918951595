import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormDto } from '../types/business.type';

// Define the state of the slice as an object
type OnboardingFormState = FormDto;

// Define an initial state
const initialState: OnboardingFormState = {
  type: 'buyer_supplier',
  name: '',
  email: '',
  phone: '',
  rcNumber: '',
  industryType: '',
  companySize: '',
  companyAddresss: [
    {
      address: '',
      country: '',
      state: '',
      postalCode: '',
      addressType: '',
    },
  ],
  businessFile: null,
  utilityFile: null,
  identificationFile: null,
  licenseFile: null,
  directors: [
    {
      fullName: '',
      number: '',
      // attachId: null,
      type: '',
    },
  ],
  representatives: [
    {
      firstName: '',
      lastName: '',
      email: '',
      position: '',
    },
  ],
  banks: [
    {
      bank: { value: '', label: '' },
      accountNumber: '',
      accountName: '',
    },
  ],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const onboardingReducer = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    resetOnboardingFormState() {
      return initialState;
    },
    setOnboardingForm(state, action: PayloadAction<FormDto>) {
      return { ...initialState, ...state, ...action.payload };
    },
  },
});

// Export each reducers function defined in createSlice
export const { resetOnboardingFormState, setOnboardingForm } = onboardingReducer.actions;

// Export default the slice reducer
export default onboardingReducer.reducer;
