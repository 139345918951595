import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import EmptyRecord from '../../../../Components/EmptyRecord';
import { Icon, Icons } from '../../../../Components/Icon';
import SearchInput from '../../../../Components/Form/SearchInput';
import FilterDropdown from '../../../../Components/Filter/FilterDropdown';
import Button from '../../../../Components/Form/Button';
import AddProduct from '../../../../Components/Modal/Product/AddProduct';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { ProductDoc, ProductType } from '../../../../types/product.type';
import { getProducts, deleteProduct } from '../../../../service/product';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import { toast } from 'react-toastify';
import Loader from '../../../../Assests/loader.gif';
import Pagination from '../../../../Components/Pagination/Pagination';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import PaginationCount from '../../../../Components/Pagination/PaginationCount';
import { SlOptionsVertical } from 'react-icons/sl';
import useOnClickOutside from '../../../../CustomHooks/useClickOutside';

const EnhancedAddProductModal = withCreatePortal(AddProduct);
const EnhancedSuccessModal = withCreatePortal(SuccessModal);

export default function Products() {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [productsData, setProductsData] = useState<ProductType | null>(null);
  const [filteredData, setFilteredData] = useState<ProductDoc[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState<ProductDoc | null>(null);
  const [productIdToDelete, setProductIdToDelete] = useState<string>('');
  const [toggle, setToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState<string>('');

  const { docs } = useAppSelector(selectBusiness);

  const fetchData = async (currentPage: number) => {
    const products = await getProducts(docs[0].businessID._id, currentPage);
    setIsLoading(false);
    setProductsData(products);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(1);
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = productsData?.docs?.filter((item: any) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData as ProductDoc[]);
  };

  const paginationClick = (num: number) => {
    setIsLoading(true);
    fetchData(num);
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(docs[0].businessID._id, productIdToDelete);
      toast.success('Product deleted successfully');
      setShowDeleteConfirmation(false);
      fetchData(currentPage); // Fetch updated product list
      setSelectedProduct(null);
    } catch (error) {
      toast.error('Error deleting product');
    }
  };

  const modalRef = useRef<HTMLUListElement | null>(null);

  const handleModalClose = () => {
    setActiveIndex('');
  };
  useOnClickOutside(modalRef, handleModalClose);

  return (
    <div>
      <Breadcrumb text="Products" subText={'All'} />
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <div className="w-1/3">
            <SearchInput
              value={search}
              placeholder="Search"
              name={''}
              className="h-10"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="flex items-center gap-2">
            <FilterDropdown
              iconPosition="left"
              icon={<Icon name={Icons.Filter} />}
              setSelectedOrder={(val) => console.log('FilterDropdown', val)}
              label="Filter"
            />
            <CheckPermissions requiredPermissions={['create_invoice']}>
              <Button
                type="button"
                variant="primary"
                size="custom"
                label="Add Product"
                className="px-5 py-2.5 text-sm"
                icon={<Icon name={Icons.Add} fill="#FFFFFF" width={15} height={15} />}
                iconPosition="left"
                iconLabelSpacing={10}
                onClick={() => {
                  if (docs[0].businessID.status === 'active') {
                    setShowAddProduct(true);
                  } else {
                    toast.info('Business is yet to be verified');
                  }
                }}
              />
            </CheckPermissions>
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="" />
          </div>
        ) : (
          <div className="relative overflow-x-auto mt-5 pb-32 panel bg-[#0353A40F] rounded-[20px]">
            <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
              <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                <tr>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Currency
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Price
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Date added
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(filteredData || productsData?.docs)?.map((item) => (
                  <tr className="bg-white/60" key={item._id}>
                    <th
                      scope="row"
                      className="p-5 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="px-6 py-4">{item.currency}</td>
                    <td className="px-6 py-4">{item.price.toLocaleString()}</td>
                    <td className="px-6 py-4">{item.description}</td>
                    <td className="px-6 py-4">
                      {new Date(item.updatedAt).toDateString()}
                    </td>
                    <td className="text-center">
                      {toggle ? (
                        <div
                          role="button"
                          tabIndex={0}
                          className={`relative flex justify-center p-2 mx-auto `}
                          onClick={() => {
                            setToggle(false);
                          }}
                        >
                          <SlOptionsVertical />
                          {activeIndex === item._id && (
                            <ul
                              ref={modalRef}
                              className="absolute left-[-60px] bottom-[-130px] z-10 flex flex-col items-start justify-between w-[150px] bg-white panel text-black"
                            >
                              <li className="py-2  w-full hover:bg-primary hover:text-white transition duration-1000 ease-out">
                                {' '}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSelectedProduct(item);
                                    setShowAddProduct(true);
                                  }}
                                  className="flex items-center gap-4 px-2 py-2 w-full"
                                >
                                  <Icon name={Icons.EditPencil} />
                                  <span>Edit </span>
                                </button>
                              </li>
                              <li className="py-2 w-full hover:bg-primary hover:text-white transition duration-1000 ease-out">
                                {' '}
                                <button
                                  className="flex items-center gap-4 px-2 py-2 w-full"
                                  type="button"
                                  onClick={() => {
                                    setSelectedProduct(item);
                                    setProductIdToDelete(item._id);
                                    setShowDeleteConfirmation(true);
                                  }}
                                >
                                  <Icon name={Icons.Delete} />
                                  <span>Delete </span>
                                </button>
                              </li>
                            </ul>
                          )}
                        </div>
                      ) : (
                        <button
                          className="p-2 relative z-[1]"
                          onClick={() => {
                            setToggle(true);
                            setActiveIndex(item?._id);
                          }}
                        >
                          <SlOptionsVertical />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-end w-[97%] mt-4 mx-auto">
              {productsData && (
                <PaginationCount
                  dataLimit={productsData?.limit}
                  currentPage={productsData?.page}
                  totalCount={productsData?.totalDocs}
                />
              )}
              <Pagination
                currentPage={currentPage}
                totalCount={productsData?.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setCurrentPage(page);
                  paginationClick(page);
                }}
              />
            </div>
            {!(filteredData || productsData?.docs || [])?.length && (
              <EmptyRecord
                title="No product to show yet"
                content="You’ve got a  blank state. Add product to get started"
                icon={Icons.Product}
              />
            )}
          </div>
        )}
      </div>

      {showAddProduct && (
        <EnhancedAddProductModal
          onCancel={() => setShowAddProduct(false)}
          onSuccess={() => {
            setShowAddProduct(false);
            setShowSuccessModal(true);
            fetchData(1);
          }}
          businessId={docs[0]?.businessID._id}
          selectedProduct={selectedProduct}
        />
      )}

      {showSuccessModal && (
        <EnhancedSuccessModal
          title={
            selectedProduct
              ? 'Product updated successfully'
              : 'Product added successfully'
          }
          description="Your product list has been updated"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}

      {showDeleteConfirmation && (
        <div className="fixed inset-0 overflow-y-auto mt-52">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <Icon name={Icons.Delete} />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Delete Product
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this product?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleDeleteProduct}
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
