import React from 'react';
import Image from '../../Assests/loader2.gif';
import './Loader.scss';

function Loader() {
  return (
    <div className="loaderx">
      <img src={Image} alt="" />
    </div>
  );
}

export default Loader;
