import { RootState } from '../store/store';
import { LoginUser } from '../types/user.type';

export const selectAccountDetails = (
  state: RootState
): { user: LoginUser; error: any } => {
  return state.account;
};

export const selectIsAuthenticated = (state: RootState) => {
  return state.account.isAuthenticated;
};

export const selectUserInformation = (state: RootState): LoginUser => {
  return state.account.user;
};

export const selectAccountError = (state: RootState) => {
  return state.account.error;
};
