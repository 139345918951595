import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '../../../Components/Form/TextField';
import Button from '../../../Components/Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useCreatePassword } from '../../../hooks/queries-and-mutations/auth';

export default function CreatePassword() {
  const navigate = useNavigate();
  const { email, token } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate, isSuccess, isError, data } = useCreatePassword();
  console.log('🚀 ~ file: CreatePassword.tsx:16 ~ CreatePassword ~ data:', data);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .nullable()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
          'Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long'
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm password is required')
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      try {
        onSubmit(values.password);
      } catch (error) {
        console.log('Error', error);
      }
    },
  });

  const onSubmit = (password: string) => {
    const payLoad = {
      email: email,
      token: token,
      newPassword: password,
    };
    mutate(payLoad);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success('');
      setIsSubmitting(false);
      navigate('/auth/login');
    }
    if (isError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  return (
    <div className="w-[430px] md:px-0 px-9">
      <div className="flex flex-col items-center">
        <p className="text-3xl font-medium">Create new password</p>
      </div>
      <div className="relative w-full max-w-[870px] mt-5">
        <div className="relative flex flex-col justify-center rounded-3xl bg-[#0353A41A] px-12 py-16">
          <div className="mx-auto w-full max-w-[440px]">
            <form className="space-y-1" onSubmit={formik.handleSubmit}>
              <TextField
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="New Password"
                showPasswordToggler
                error={formik.touched.password ? formik.errors.password : ''}
              />
              <TextField
                name="confirmPassword"
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Confirm password"
                showPasswordToggler
                error={
                  formik.touched.confirmPassword ? formik.errors.confirmPassword : ''
                }
              />
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label="Login"
                  className="mt-10"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || isSubmitting}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
