import React, { useCallback, useState } from 'react';
import { InfoModal } from '../InfoModal';
import Button from '../../Form/Button';
import withCreatePortal from '../../Hoc/withCreatePortal';
import SuccessModal from '../SuccessModal';
import ConfirmModal from '../ConfirmModal';
import DelinePaymentModal from './DelinePaymentModal';
import TextField from '../../Form/TextField';
import paymentProof from '../../../Assests/payment-proof.png';

type Props = {
  onCancel: () => void;
};

const EhancedConfirmModal = withCreatePortal(ConfirmModal);
const EhancedSuccessModal = withCreatePortal(SuccessModal);
const EhancedDelinePaymentModal = withCreatePortal(DelinePaymentModal);

export default function ProofOfPaymentModal({ onCancel }: Props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const renderModal = useCallback(() => {
    if (showConfirmModal) {
      return (
        <EhancedConfirmModal
          content={'Are you sure you want to confirm this payment ?'}
          onConfirm={() => {
            setShowSuccessModal(true);
            setShowConfirmModal(false);
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Confirm"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
        />
      );
    } else if (showSuccessModal) {
      return (
        <EhancedSuccessModal
          title="Payment Confimed"
          description="Payment of 50,0000 from favourie promise has been confirmed"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      );
    } else if (showDeclineModal) {
      return (
        <EhancedDelinePaymentModal
          onCancel={() => setShowDeclineModal(false)}
          onSuccess={() => setShowDeclineModal(false)}
        />
      );
    } else {
      return (
        <InfoModal width="w-[40%]" className="h-fit " onClose={onCancel}>
          <div className="font-circular text-2xl font-normal text-[#353535]">
            <div className="flex flex-col justify-between">
              <div className="py-5 border-b">
                <p className="text-xl font-medium font-inter text-black">
                  Proof of Payment
                </p>
                <p className="text-base font-normal font-inter text-black/40">
                  Confirm payment of ₦100,000.00 from Favourite Promise
                </p>
              </div>

              <div className="pt-6 mt-5 w-full">
                <TextField
                  name="price"
                  type="text"
                  value={'50000'}
                  label="Amount Paid"
                  className="bg-[#8E8E8E0F]"
                  disabled
                />
                <div className="flex flex-col items-center my-9">
                  <img src={paymentProof} alt="payment proof" />
                </div>

                <div className="flex gap-20 items-center w-full mt-5">
                  <Button
                    type="button"
                    variant="primary"
                    size="lg"
                    label="Confirm"
                    className="my-5"
                    onClick={() => setShowConfirmModal(true)}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    label="Decline"
                    className="my-5 px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                    onClick={() => setShowDeclineModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </InfoModal>
      );
    }
  }, [onCancel, showConfirmModal, showDeclineModal, showSuccessModal]);

  return renderModal();
}
