import React from 'react';
import { motion } from 'framer-motion';
type Props = {
  children: React.ReactNode;
  initialOpacity?: number;
  animatedOpacity?: number;
  initialHeight?: number | string;
  animatedHeight?: number | string;
  ease?: number[] | string;
  exitEase?: number[] | string;
  animateDuration?: number;
  exitDuration?: number;
  animationType?: string;
  overflow?: string;
};

const DropDown: React.FC<Props> = ({
  children,
  initialOpacity = 0,
  animatedOpacity = 1,
  initialHeight = 0,
  animatedHeight = 'auto',
  ease = [0.79, 0.14, 0.15, 0.86],
  exitEase = [0.65, 0.05, 0.36, 1],
  animateDuration = 0.64,
  exitDuration = 0.64,
  overflow = 'hidden',
}) => {
  return (
    <motion.div
      initial={{ opacity: initialOpacity, height: initialHeight }}
      animate={{
        opacity: animatedOpacity,
        height: animatedHeight,
        overflow: overflow,
        transition: {
          duration: animateDuration,
          ease: ease,
        },
      }}
      exit={{
        height: initialHeight,
        opacity: initialOpacity,
        transition: {
          duration: exitDuration,
          ease: exitEase,
        },
      }}
    >
      {children}
    </motion.div>
  );
};

export default DropDown;
