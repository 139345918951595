import { useCallback, useEffect, useState } from 'react';
import './Auth.scss';
import Loader from '../../Assests/loader.gif';
import Image from '../../Assests/Group 3465459.png';
import { ReactComponent as Enter } from '../../Assests/Enter.svg';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import Navbar from '../Landing/components/Navbar';
import { errorHandler } from '../../service';

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
};
interface Props {
  notify: (type: string, message: string) => void;
}

const baseUrl = process.env.REACT_APP_API_URL;

function Create({ notify }: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const [formLoading, setFormLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }, //Added isValid and isSubmitting from formik, isValid does the check while isSubmitting ensures it remains disabled while it's submitting
    getValues,
    setValue,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const verifyToken = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/business/token-verification?token=${token}`
      );
      setValue('email', response.data.data.email);
      setRegisteredEmail(response.data.data.email);
    } catch (error) {
      const errorMessage = error as AxiosError<Error>;
      notify('error', errorHandler(errorMessage));

      setTimeout(() => {
        navigate('/create-account');
      }, 1000);
    }
  }, [token, notify, setValue, navigate]);

  useEffect(() => {
    if (token) {
      verifyToken();
    }
  }, [token, verifyToken]);

  const onSubmit = async (data: FormData) => {
    setFormLoading(true);
    const payLoad = {
      email: data.email.toLocaleLowerCase(),
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
    };
    try {
      const response = await axios.post(`${baseUrl}/auth/user/create`, payLoad);
      notify('success', response.data.message);
      setFormLoading(false);
      setTimeout(() => {
        navigate('/auth/login');
      }, 1000);
    } catch (error) {
      setFormLoading(false);
      const errMessage = error as AxiosError<Error>;
      notify('error', errorHandler(errMessage));
    }
  };

  const validatePassword = (value: string) => {
    const isValid = /^[a-zA-Z0-9-!@#$%^&*()_+\-=[\]{}|;':",.<>/?]+$/.test(value);
    return (
      isValid || 'Password should be alphanumeric and may contain special characters'
    );
  };

  return (
    <div className="auth">
      <Navbar label={'auth'} />
      <div className="register">
        <div className="right">
          <img src={Image} alt="" />
        </div>
        <div className="left dif">
          <div className="left-body">
            <h2>Create account</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <p>First Name</p>
              <input
                type="text"
                {...register('firstName', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: 'First name can only contain letters',
                  },
                })}
                id=""
                placeholder="First Name"
              />
              {errors.firstName && <span>This field is required</span>}
            </div>
            <div className="card">
              <p>Last Name</p>
              <input
                type="text"
                {...register('lastName', { required: true })}
                id=""
                onKeyDown={(event) => {
                  const char = event.key;
                  if (!/^[a-zA-Z]+$/.test(char) && char !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
              />
              {errors.lastName && <span>This field is required</span>}
            </div>
            <div className="card">
              <p>Email</p>
              <input
                type="email"
                id=""
                disabled={registeredEmail ? true : false}
                {...register('email', { required: true })}
              />
              {errors.email && <span>This field is required</span>}
            </div>
            <div className="cards">
              <p> Password</p>
              <div className="input">
                <input
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: true,
                    minLength: 6,
                    validate: validatePassword,
                  })}
                  id=""
                />
                {showPassword ? (
                  <AiOutlineEye onClick={() => setShowPassword(false)} />
                ) : (
                  <AiOutlineEyeInvisible onClick={() => setShowPassword(true)} />
                )}
              </div>
              {errors.password && errors.password.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.password && errors.password.type === 'minLength' && (
                <span>Password must be at least 6 characters</span>
              )}
              {errors.password && errors.password.type === 'validate' && (
                <span>{errors.password.message}</span>
              )}
            </div>
            <div className="cards">
              <p> Confirm Password</p>
              <div className="input">
                <input
                  type={showPassword2 ? 'text' : 'password'}
                  onPaste={(e) => e.preventDefault()}
                  {...register('confirmPassword', {
                    required: true,
                    validate: (value) =>
                      value === getValues('password') || 'Passwords do not match',
                  })}
                  id=""
                />
                {showPassword2 ? (
                  <AiOutlineEye onClick={() => setShowPassword2(false)} />
                ) : (
                  <AiOutlineEyeInvisible onClick={() => setShowPassword2(true)} />
                )}
              </div>
              {errors.confirmPassword && errors.confirmPassword.type === 'required' && (
                <span>This field is required</span>
              )}
              {errors.confirmPassword && errors.confirmPassword.type === 'validate' && (
                <span>{errors.confirmPassword.message}</span>
              )}
            </div>
            <div className="card">
              {formLoading ? (
                <div className="loader">
                  <img src={Loader} alt="" />
                </div>
              ) : (
                <button disabled={!isValid || isSubmitting} type="submit">
                  <p>Create Account</p>
                  <Enter />
                </button>
              )}
            </div>
          </form>
          <div
            role="button"
            tabIndex={0}
            className="left-tag"
            onClick={() => navigate('/login')}
          >
            <p>Login if you have an account already</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Create;
