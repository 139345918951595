import { AxiosError } from 'axios';
import { baseService } from '../hooks/queries-and-mutations';
import { errorHandler, getToken } from './';
import { BillingHistory, CardType, CurrentPlan, Plan } from '../types/subscription.type';
import { toast } from 'react-toastify';

export const getCards = async (businessId: string): Promise<CardType[]> => {
  try {
    const response = await baseService.get(`/subscription/${businessId}/cards`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to get your card');
    return errorHandler(err);
  }
};

export const deleteCard = async (businessId: string, cardId: string): Promise<void> => {
  try {
    const response = await baseService.delete(`/subscription/${businessId}/${cardId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to delete card');
    return errorHandler(err);
  }
};

export const markCardAsDefault = async (
  businessId: string,
  cardId: string
): Promise<void> => {
  try {
    const response = await baseService.post(
      `/subscription/${businessId}/${cardId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to mark card as default');
    return errorHandler(err);
  }
};

export const getPlans = async (): Promise<Plan[]> => {
  try {
    const response = await baseService.get(`/subscription/plans`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to get plans');
    return errorHandler(err);
  }
};

export const getCurrentPlan = async (businessId: string): Promise<CurrentPlan> => {
  try {
    const response = await baseService.get(`/subscription/${businessId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getBillingHistory = async (
  businessId: string
): Promise<BillingHistory[]> => {
  try {
    const response = await baseService.get(`/subscription/${businessId}/history`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to get billing history');
    return errorHandler(err);
  }
};

export const subscribePlan = async (
  businessId: string,
  reference: string,
  seat: number
): Promise<void> => {
  try {
    const response = await baseService.post(
      `/subscription/${businessId}/subscribe/${reference}`,
      { seat },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to subscribe to a plan');
    return errorHandler(err);
  }
};

export const addCard = async (businessId: string, reference: string): Promise<void> => {
  try {
    const response = await baseService.put(
      `/subscription/${businessId}/${reference}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, An error has occurred while trying to add a card');
    return errorHandler(err);
  }
};
