import React, { useState } from 'react';
import Button from '../../../../Components/Form/Button';
import SubscriptionMenu from './Subscription/SubscriptionMenu';
import Billing from './Subscription/Billing';

export default function Account() {
  const [activeTab, setActiveTab] = useState('Subscription');

  return (
    <div className="w-full">
      <div className="border-b pl-9 py-5">
        <p className="font-semibold text-2xl font-inter text-black">Account</p>
      </div>
      <div className="p-8 px-12">
        <div className="flex items-center gap-12">
          <Button
            type="button"
            onClick={() => setActiveTab('Subscription')}
            variant="custom"
            size="custom"
            label="Subscription"
            className={`py-2.5  ${
              activeTab === 'Subscription'
                ? 'px-4 bg-[#B9D6F2] text-[#0353A4]'
                : 'text-black bg-transparent font-medium'
            }`}
          />
          <Button
            type="button"
            onClick={() => setActiveTab('Billing Method')}
            variant="custom"
            size="custom"
            label="Billing Method"
            className={`py-2.5 ${
              activeTab === 'Billing Method'
                ? 'px-4 bg-[#B9D6F2] text-[#0353A4]'
                : 'text-black bg-transparent font-medium'
            }`}
          />
        </div>

        {activeTab === 'Subscription' && <SubscriptionMenu />}
        {activeTab === 'Billing Method' && <Billing />}
      </div>
    </div>
  );
}
