import React, { useState } from 'react';
import { InfoModal } from './InfoModal';
import TextField from '../Form/TextField';
import Button from '../Form/Button';
import { ApprovalType, ApprovalTypeDoc, IChosenUsers } from '../../types/approval.type';

type Props = {
  onCancel: () => void;
  onSubmit: (args: any[]) => void;
  persons: ApprovalType | undefined;
  setChosen: React.Dispatch<React.SetStateAction<IChosenUsers[]>>;
};

export default function AddPersonModal({
  onCancel,
  onSubmit,
  persons,
  setChosen,
}: Props) {
  const [selectedPerson, setSelectedPerson] = useState<string[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<ApprovalTypeDoc[] | null>(null);

  const handleCheckboxChange = (value: string, user: ApprovalTypeDoc) => {
    const selectedUser: IChosenUsers = {
      _id: user._id,
      name: `${user?.firstName + ' ' + user?.lastName}`,
    };

    setChosen((prev: IChosenUsers[]) => {
      const checkIfPresent = prev.some((res: IChosenUsers) => res._id === user._id);

      if (checkIfPresent) {
        return prev.filter((res: any) => res._id !== user._id);
      } else {
        return [...prev, selectedUser];
      }
    });
    if (selectedPerson.includes(value as never)) {
      setSelectedPerson(selectedPerson.filter((item) => item !== value));
    } else {
      setSelectedPerson((prevState) => [...prevState, value]);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = persons?.docs.filter(
      (item) =>
        item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData as ApprovalTypeDoc[]);
  };

  return (
    <InfoModal width="w-[45%]" className="h-fit " onClose={onCancel}>
      <div className="rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="py-5 text-left max-w-xss border-b pb-3">
            <p className="text-xl font-medium font-inter text-black">Add Person</p>
            <p className="text-sm font-normal font-inter text-black/40">
              Search and add person
            </p>
          </div>

          <div className="mx-auto w-full my-8">
            <TextField
              name="search"
              type="text"
              value={search}
              onChange={handleSearch}
              label="Search"
              placeholder="Search name"
              className="bg-white"
            />
            <div className="space-y-0.5">
              <div className="py-6">
                {persons &&
                  (filteredData || persons?.docs || [])?.map((item) => (
                    <div
                      className="flex items-center justify-between py-4"
                      key={item._id}
                    >
                      <p className="text-black font-normal text-sm">{`${item?.firstName} ${item?.lastName}`}</p>
                      <input
                        type="checkbox"
                        className="form-checkbox text-dark"
                        onChange={() => {
                          handleCheckboxChange(item?._id, item);
                        }}
                        checked={selectedPerson.includes(item?._id as never)}
                      />
                    </div>
                  ))}
              </div>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  variant="primary"
                  size="custom"
                  label="Save"
                  className="mt-8 p-3 w-[8rem]"
                  onClick={() => {
                    onSubmit(selectedPerson);
                  }}
                  disabled={persons && persons.docs.length < 1 ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
