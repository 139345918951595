import { AxiosError } from 'axios';
import { AuditType } from '../types/audit.type';
import { baseService, errorHandler, getToken } from '.';

export const getAudits = async (businessId: any): Promise<AuditType> => {
  try {
    const response = await baseService.get(`audittrail/${businessId}/fetch`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
