import { useState } from 'react';
import ModalPortal from '../../ModalPortal';
import { Icon, Icons } from '../../Icon';
import Button from '../../Form/Button';

type Props = {
  onCancel: () => void;
  onAddOneByOne: () => void;
  onAddBulk: () => void;
};

export default function AddVendorMenu({ onCancel, onAddOneByOne, onAddBulk }: Props) {
  const [selectedOption, setSelectedOption] = useState<'one' | 'bulk' | ''>('');

  const handleSubmit = () => {
    if (selectedOption === 'one') {
      onAddOneByOne();
      return;
    }
    if (selectedOption === 'bulk') {
      onAddBulk();
      return;
    }
  };

  return (
    <ModalPortal>
      <div className="fixed top-0 z-50 flex h-screen w-screen items-center justify-center bg-[#000000] bg-opacity-60">
        <div className="w-2/5 px-8">
          <div className="flex flex-col items-end justify-end">
            <div role="button" tabIndex={0} className="cursor-pointer" onClick={onCancel}>
              <Icon name={Icons.Close} />
            </div>
          </div>
          <div className="mr-12 p-10 bg-white rounded-[20px]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-center">
                <p className="text-xl font-semibold font-inter">Add Vendor</p>
                <span className="text-base font-normal text-black/40 font-inter">
                  Select mode of adding vendor
                </span>
              </div>

              <div className="flex gap-6 mt-10">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedOption('one')}
                  className={`w-full p-2 text-center max-w-[14rem] cursor-pointer border ${
                    selectedOption === 'one'
                      ? 'border-[#0353A4] bg-[#B9D6F238]'
                      : 'bg-white border-black/20'
                  } rounded-lg shadow`}
                >
                  {selectedOption === 'one' ? (
                    <div className="flex justify-end">
                      <Icon name={Icons.CheckMark} />
                    </div>
                  ) : (
                    <div className="h-[18px]" />
                  )}
                  <div className="flex flex-col items-center justify-center p-4">
                    <Icon name={Icons.SingleFile} />
                    <div className="p-4">
                      <h5 className="text-sm font-medium text-black font-inter">
                        Add one by one
                      </h5>
                      <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                        Add vendor one at a time
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedOption('bulk')}
                  className={`w-full p-2 text-center max-w-[14rem] cursor-pointer border ${
                    selectedOption === 'bulk'
                      ? 'border-[#0353A4] bg-[#B9D6F238]'
                      : 'bg-white border-black/20'
                  } rounded-lg shadow`}
                >
                  {selectedOption === 'bulk' ? (
                    <div className="flex justify-end">
                      <Icon name={Icons.CheckMark} />
                    </div>
                  ) : (
                    <div className="h-[18px]" />
                  )}
                  <div className="flex flex-col items-center justify-center p-4">
                    <Icon name={Icons.MultipleFile} />
                    <div className="p-4">
                      <h5 className="text-sm font-medium text-black font-inter">
                        Bulk upload in csv
                      </h5>
                      <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                        Upload multiple vendor
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                type="button"
                variant="primary"
                size="md"
                label="Continue"
                className="mt-10"
                disabled={!selectedOption}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}
