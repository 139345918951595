import React, { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../../../Components/Icon';
import {
  useAddCard,
  useDeleteCard,
  useGetCards,
  useMarkCardAsDefault,
} from '../../../../../hooks/queries-and-mutations/subscription';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { toast } from 'react-toastify';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';
import SuccessModal from '../../../../../Components/Modal/SuccessModal';
import DeleteModal from '../../../../../Components/Modal/DeleteModal';
import Paystack from '../../../../../Components/Paystack';
import { PaystackSuccessDto } from '../../../../../types/payment.type';

const EhancedDeleteModal = withCreatePortal(DeleteModal);
const EhancedSuccessModal = withCreatePortal(SuccessModal);

export default function Billing() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCardAddSuccessModal, setShowAddCardSuccessModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState('');

  const { docs } = useAppSelector(selectBusiness);
  const { data: cards, refetch } = useGetCards({ businessId: docs[0].businessID._id });
  const {
    mutate: deleteCard,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
  } = useDeleteCard();
  const { mutate: markCardAsDefault, isSuccess, isError } = useMarkCardAsDefault();
  const {
    mutate: addCard,
    isSuccess: addCardSuccess,
    isError: addCardError,
  } = useAddCard();

  const handleMarkCard = (cardId: string) => {
    markCardAsDefault({ businessId: docs[0].businessID._id, cardId });
  };

  const handleDeleteCard = (cardId: string) => {
    deleteCard({ businessId: docs[0].businessID._id, cardId });
  };

  useEffect(() => {
    if (isDeleteSuccess && !isDeleteError) {
      setIsSubmitting(false);
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      refetch();
    } else if (isDeleteError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSuccess, isDeleteError]);

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      toast.success('Card has been mark as default');
      refetch();
    } else if (isError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const onPaymentSuccess = (payload: PaystackSuccessDto) => {
    if (payload.message === 'Approved' && payload.status === 'success') {
      addCard({ businessId: docs[0].businessID._id, reference: payload.reference });
    }
  };

  useEffect(() => {
    if (addCardSuccess && !addCardError) {
      setIsSubmitting(false);
      setShowAddCardSuccessModal(true);
      refetch();
    } else if (addCardError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCardSuccess, addCardError]);

  return (
    <div className="mt-6">
      <p className="text-lg font-medium">Saved Cards</p>

      {cards?.map((card) => (
        <div className="my-9" key={card._id}>
          <div className="bg-[#B9D6F224] p-8 py-4 rounded-[5px]">
            <div className="flex items-center justify-between">
              <div className="flex flex-col items-center gap-5">
                <div className="flex items-center gap-5">
                  <Icon name={Icons.Card} />
                  <div className="flex flex-col">
                    <p className="font-medium text-base">****{card.last4}</p>
                    <p className="font-medium text-xs text-black/40">{card.brand}</p>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <input
                    type="checkbox"
                    className="form-checkbox text-dark"
                    onChange={() => handleMarkCard(card._id)}
                    checked={card.default_card}
                    disabled={card.default_card || isSubmitting}
                  />{' '}
                  <span className="font-medium text-sm">
                    {card.default_card ? 'Active card' : 'Set as active card'}
                  </span>
                </div>
              </div>
              <button
                type="button"
                disabled={isSubmitting}
                className="justify-end"
                onClick={() => {
                  setSelectedCardId(card._id);
                  setShowConfirmModal(true);
                }}
              >
                <Icon name={Icons.Delete} />
              </button>
            </div>
          </div>

          <div className="flex items-center mt-8 pb-9" role="button">
            <div className="flex items-center gap-1">
              <Icon name={Icons.Add} />
              <Paystack
                isProcessing={false}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentClose={() => {
                  toast.error('Payment close');
                }}
                amount={5}
                className="text-sm font-medium text-[#000000]"
                text="Add another card"
                email={docs[0]?.businessID?.email}
              />
            </div>
            <div className="line ml-5" style={{ width: '85%' }} />
          </div>
        </div>
      ))}

      {!cards?.length && (
        <div className="my-9">
          <div className="bg-[#B9D6F224] p-10 rounded-[5px]">
            <div className="flex flex-col items-center justify-center">
              <p className="text-lg font-medium text-black">No card added yet</p>
              <div className="flex items-center gap-2 mt-3">
                <Icon name={Icons.Add} fill="#0353A4" />
                <Paystack
                  isProcessing={false}
                  onPaymentSuccess={onPaymentSuccess}
                  onPaymentClose={() => {
                    toast.error('Payment close');
                  }}
                  amount={5}
                  className="font-medium text-[#0353A4] text-sm"
                  text="Add a card"
                  email={docs[0]?.businessID?.email}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <EhancedDeleteModal
          title="Delete card"
          content={'Are you sure you want to delete this card'}
          onConfirm={() => {
            handleDeleteCard(selectedCardId);
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Delete"
          cancelClassName="w-[7rem] border border-[#AA1414] rounded bg-transparent text-[#AA1414] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#AA1414] rounded font-normal text-white"
        />
      )}
      {showSuccessModal && (
        <EhancedSuccessModal
          title="Card deleted successfully"
          description="Your card has been deleted"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
      {showCardAddSuccessModal && (
        <EhancedSuccessModal
          title="Card saved successfully"
          description="Your card has been added successfully"
          cancelText="Back"
          onCancel={() => {
            setShowAddCardSuccessModal(false);
          }}
        />
      )}
    </div>
  );
}
