import React, { useState } from 'react';
import FilterMoreOption from './FilterMoreOption';
import { Icon, Icons } from '../Icon';

interface IProps {
  setSelectedOrder: (arg: string | null) => void;
  iconPosition?: 'left' | 'right';
  label: string;
  icon?: React.ReactNode;
  title?: string;
  handleTableSearch?: (val: string) => void;
  options?: { value: string; label: string }[];
  disabled?: boolean;
  activeIndicator?: string;
  all?: string;
}

const FilterDropdown: React.FC<IProps> = ({
  handleTableSearch,
  label,
  options,
  disabled,
  activeIndicator,
  all,
}) => {
  const [isSortDropdownVisible, setIsSortDropdownVisible] = useState(false);

  return (
    <div
      className={`relative z-20 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} `}
    >
      <button
        disabled={disabled}
        onClick={() => {
          setIsSortDropdownVisible((prev) => !prev);
        }}
        type="button"
        className={`${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } text-black border border-[#00000021] gap-3 bg-white/20 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center me-2`}
      >
        <Icon name={Icons.Filter} />
        {label}
      </button>
      {isSortDropdownVisible && (
        <FilterMoreOption
          applyFilter={(val) => {
            handleTableSearch && handleTableSearch(val);
            setIsSortDropdownVisible(false);
          }}
          options={options}
          activeIndicator={activeIndicator}
          setIsSortDropdownVisible={setIsSortDropdownVisible}
          all={all}
        />
      )}
    </div>
  );
};

export default FilterDropdown;
