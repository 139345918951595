import React from 'react';
import { Icon, Icons } from './Icon';

type Props = {
  icon: Icons;
  title: string;
  content: string;
};

export default function EmptyRecord({ icon, title, content }: Props) {
  return (
    <div className="mt-14s p-32">
      <div className="flex flex-col items-center justify-center w-full p-4 text-center sm:p-8">
        <Icon name={icon} width={61} height={61} fill="#061A40" />
        <h5 className="mb-2 text-2xl font-inter font-semibold text-[#0F1124] mt-3">
          {title}
        </h5>
        <p className="mb-5 text-sm text-[#74757F] sm:text-lg">{content}</p>
      </div>
    </div>
  );
}
