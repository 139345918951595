import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '../../../Components/Form/TextField';
import Button from '../../../Components/Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { login, logout } from '../../../thunks/account-thunk';
import {
  selectAccountDetails,
  selectIsAuthenticated,
} from '../../../selectors/account-selector';
import { toast } from 'react-toastify';
import { useGetBussiness } from '../../../hooks/queries-and-mutations/business';

export default function Login() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { user, error: loginError } = useAppSelector(selectAccountDetails);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
      password: Yup.string().required('Password is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const { email, password } = values;
      dispatch(login({ email: email.trim(), password: password.trim(), dispatch }));
    },
  });

  useEffect(() => {
    if (loginError) {
      formik.setSubmitting(false);
      setIsSubmitting(false);
      toast.error(loginError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginError, navigate]);

  useGetBussiness({ isAuthenticated });

  useEffect(() => {
    if (isAuthenticated) {
      if (!user) {
        dispatch(logout());
        navigate('/auth/login');
      }
    }
  }, [isAuthenticated, user]);

  return (
    <div className="w-[430px] md:px-0 px-9">
      <div className="flex flex-col items-center">
        <p className="text-3xl font-medium">Welcome back</p>
        <span className="text-sm font-normal">Please enter your details below</span>
      </div>
      <div className="relative w-full max-w-[870px] mt-5">
        <div className="relative flex flex-col justify-center rounded-3xl bg-[#0353A41A] px-12 py-16">
          <div className="mx-auto w-full max-w-[440px]">
            <form className="space-y-1" onSubmit={formik.handleSubmit}>
              <TextField
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Email"
                error={formik.touched.email ? formik.errors.email : ''}
              />
              <TextField
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Password"
                showPasswordToggler
                error={formik.touched.password ? formik.errors.password : ''}
              />
              <div>
                <div className="mt-4">
                  <span className="flex items-center justify-end">
                    <Link to="/auth/forgot-password">
                      <span className="text-[#061A40] font-medium text-sm">
                        Forgot Password
                      </span>
                    </Link>
                  </span>
                </div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label="Login"
                  className="mt-10"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || isSubmitting}
                />
              </div>
            </form>
            <div className="text-center text-[#00000066] text-xs mt-4">
              Don't have an account?
              <Link to="/auth/create-account" className="text-[#061A40] ml-1">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
