import Avatar from '../../Assests/avatar.png';

interface ImageWithFallbackProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt }) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = Avatar;
  };

  return <img src={src} onError={handleImageError} alt={alt} />;
};

export default ImageWithFallback;
