import Button from '../Form/Button';
import { Icon, Icons } from '../Icon';
import { InfoModal } from './InfoModal';

type Props = {
  title: string;
  description: string;
  onCancel: () => void;
  cancelText: string;
  isDraft?: boolean;
};

export default function ErrorModal({ title, description, onCancel, cancelText }: Props) {
  return (
    <InfoModal width="w-[25%]" className="h-fit " onClose={onCancel}>
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          <Icon name={Icons.Error} />
          <div className="max-w-xs p-5 text-center">
            <p className="text-xl font-medium text-black font-inter">{title}</p>
            <p className="text-sm font-normal text-black font-inter">{description}</p>
          </div>
          <div className="flex items-center w-full">
            <Button
              variant="primary"
              size="lg"
              className="mb-3 w-[100rem] rounded px-[1.5rem] py-1 text-sm font-normal"
              label={cancelText}
              onClick={onCancel}
            ></Button>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
