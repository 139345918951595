import { useEffect, useState } from 'react';
import { InfoModal } from './InfoModal';
import TextField from '../Form/TextField';
import CustomSelect from '../Form/CustomSelect';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Plan } from '../../types/subscription.type';
import { formatCurrency } from '../../utils';
import Paystack from '../Paystack';
import { toast } from 'react-toastify';
import { PaystackSuccessDto } from '../../types/payment.type';
import { usePlanSubscription } from '../../hooks/queries-and-mutations/subscription';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  plan: Plan;
  email: string;
  businessId: string;
};

export default function PaymentModal({
  onCancel,
  onSuccess,
  plan,
  email,
  businessId,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsSubmitting] = useState(false);

  const { mutate, isSuccess, isError } = usePlanSubscription();

  const formik = useFormik({
    initialValues: {
      plan: { label: plan.name, value: plan._id },
      period: 'Monthly',
      seat: 1,
    },
    validationSchema: Yup.object({
      plan: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Plan is required')
        .nullable(),
      period: Yup.string().required('Period is required').nullable(),
      seat: Yup.number().required('Number of seat is required').nullable(),
    }),
    onSubmit: (values, { setFieldTouched }) => {
      setIsSubmitting(true);
      for (const key in values) {
        setFieldTouched(key, true);
      }
    },
  });

  const onPaymentSuccess = (payload: PaystackSuccessDto) => {
    if (payload.message === 'Approved' && payload.status === 'success') {
      mutate({ businessId, reference: payload.reference, seat: formik.values.seat });
    }
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      toast.success('Subscription successfully');
      onSuccess();
    } else if (isError) {
      setIsSubmitting(false);
    }
  }, [isError, isSuccess]);

  return (
    <div>
      <InfoModal width="w-[50%]" className="h-fit " onClose={onCancel} usePadding={false}>
        <div className="rounded-[20px] bg-white font-circular text-2xl font-normal text-[#353535]">
          <div className="flex flex-wrap justify-between">
            <div className="w-full mb-6 lg:w-1/2">
              <div className="p-8">
                <p className="text-2xl font-semibold text-black">Your plan</p>
                <p className="text-sm font-normal text-black/40">
                  Select a plan and enter preferred number of seats
                </p>
              </div>
              <form className="py-4 pl-8" onSubmit={formik.handleSubmit}>
                <div className="mb-5">
                  <CustomSelect
                    options={[{ label: plan.name, value: plan._id }]}
                    selectedOption={formik.values.plan}
                    handleOptionChange={(option) => {
                      formik.setFieldValue('plan', option);
                    }}
                    label="Package"
                    name="plan"
                    disabled
                    isSearchable
                    className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                    error={
                      formik.touched.plan || formik.errors.plan
                        ? 'Package is required'
                        : ''
                    }
                  />
                </div>
                <div className="grid grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                  <div>
                    <CustomSelect
                      options={[
                        { label: 'Monthly', value: 'Monthly' },
                        { label: 'Yearly', value: 'Yearly' },
                      ]}
                      selectedOption={{
                        label: formik.values.period,
                        value: formik.values.period,
                      }}
                      handleOptionChange={(option) => {
                        formik.setFieldValue('period', option?.value);
                      }}
                      label="Period"
                      name="period"
                      isSearchable
                      className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                      error={
                        formik.touched.plan || formik.errors.plan
                          ? 'Package is required'
                          : ''
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      name="seat"
                      type="number"
                      value={formik.values.seat}
                      onChange={formik.handleChange}
                      min={1}
                      onBlur={formik.handleChange}
                      label="Seat"
                      error={formik.touched.seat ? formik.errors.seat : ''}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="w-[45%] max-w-fits px-3 bg-[#8E8E8E0F] pb-24">
              <div className="p-8">
                <p className="text-2xl font-semibold text-black">Order Summary</p>
              </div>
              <div className="mt-6 flex justify-between px-4 font-medium text-sm text-[#4241419C]">
                <div className="w-full space-y-7">
                  <div className="flex items-center justify-between">
                    <div>Price/person</div>
                    <div className="text-black">
                      {formatCurrency(plan?.currency, plan?.price_per_seat)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Billing</div>
                    <div className="text-black">{formik.values.period}</div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Number of seat</div>
                    <div className="text-black">{formik.values.seat}</div>
                  </div>
                  <hr className="border-[#0000001A]" />
                  <div className="flex items-center justify-between mt-4">
                    <div>Sub total</div>
                    <div className="text-black">
                      {formatCurrency(
                        plan?.currency,
                        plan?.price_per_seat * formik.values.seat
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Tax</div>
                    <div className="text-black">$0.00</div>
                  </div>
                  <hr className="border-[#0000001A]" />
                  <div className="flex items-center justify-between mt-4 text-sm font-medium text-black">
                    <div>Total</div>
                    <div>
                      {formatCurrency(
                        plan?.currency,
                        plan?.price_per_seat * formik.values.seat
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Paystack
                isProcessing={false}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentClose={() => {
                  toast.error('Payment close');
                }}
                amount={plan?.price_per_seat * formik.values.seat}
                email={email}
                className="mt-5 rounded-[10px] text-base font-medium w-full bg-[#061A40] p-3 text-white"
                text="Subscribe"
              />
              <p className="text-black/60 text-[10px] font-normal text-center">
                By continuing, you agree to the Paytton Terms and Condition
              </p>
            </div>
          </div>
        </div>
      </InfoModal>
    </div>
  );
}
