import React from 'react';
import { motion } from 'framer-motion';

interface StaggeredAnimationProps {
  children: React.ReactNode;
  delay?: number;
  index?: number;
  onClick?: () => void;
  className?: string;
}

const StaggerTable = ({
  children,
  index,
  onClick,
  className,
}: StaggeredAnimationProps) => {
  const childVariants = {
    hidden: { opacity: 0, x: 20 },
    show: { opacity: 1, x: 0 },
  };

  return (
    <motion.tr
      className={className}
      onClick={onClick}
      key={index}
      variants={childVariants}
      initial="hidden"
      animate="show"
    >
      {children}
    </motion.tr>
  );
};

export default StaggerTable;
