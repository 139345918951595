import { RoleDoc } from '../../../types/setting.type';
import Button from '../../Form/Button';
import { Icon, Icons } from '../../Icon';
import { InfoModal } from '../InfoModal';

type Props = {
  title: string;
  description: string;
  onCancel: () => void;
  cancelText: string;
  isSuccessful?: boolean;
  selectedRole?: RoleDoc | null;
};

export default function DisabledSuccessModal({
  title,
  description,
  onCancel,
  cancelText,
  isSuccessful,
  selectedRole,
}: Props) {
  return (
    <InfoModal width="w-[30%]" className="h-fit " onClose={onCancel}>
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          {isSuccessful && <Icon name={Icons.Success} />}
          <div className="max-w-xs p-5 text-center">
            <p className="text-xl font-medium text-black font-inter">
              {selectedRole ? 'Role disabled successfully' : title}
            </p>
            <p className="text-sm font-normal text-black font-inter mt-2">
              {selectedRole
                ? `The role ${selectedRole?.name} has been disabled successfully.`
                : description}
            </p>
          </div>
          <div className="flex items-center w-full">
            <Button
              variant="primary"
              size="lg"
              className="mb-3 w-[100rem] rounded px-[1.5rem] py-1 text-sm font-normal"
              label={cancelText}
              onClick={onCancel}
            ></Button>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
