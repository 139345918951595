import { useEffect, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import Button from '../../../../Components/Form/Button';
import { Icon, Icons } from '../../../../Components/Icon';
import avatar from '../../../../Assests/avatar-1.png';
import UploadPreview from '../../../../Components/Form/UploadPreview';
import EditVendorModal from '../../../../Components/Modal/Vendor/EditVendorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useGetVendor } from '../../../../hooks/queries-and-mutations/vendor';
import { useParams } from 'react-router-dom';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useAppSelector } from '../../../../store/hooks';
import Loader from '../../../../Assests/loader.gif';
import Status from '../../../../Components/Status';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import BlacklistModal from '../../../../Components/Modal/Vendor/BlacklistModal';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import DeclineVendorModal from '../../../../Components/Modal/Vendor/DeclineVendorModal';
import VendorApprovalFlow from './VendorApprovalFlow';
import {
  DirectorsDetail,
  KybDocument,
  RepsDetail,
  SingleVendorType,
} from '../../../../types/vendor.type';
import { AnimatePresence } from 'framer-motion';
import DropDown from '../../../../Components/Animations/DropDown';

const EnhancedBlacklistModal = withCreatePortal(BlacklistModal);
const EnhancedEditVendorModal = withCreatePortal(EditVendorModal);
const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmModal);
const EnhancedDeclineVendorModal = withCreatePortal(DeclineVendorModal);
export default function VendorProfile() {
  const [activeTab, setActiveTab] = useState('');
  const [showBlacklistModal, setShowBlackListModal] = useState(false);
  const [showEditVendorModal, setShowEditVendorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [wantToBlackList, setWantToBlackList] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showApproveSuccessModal, setShowApproveSuccessModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const { vendorId } = useParams();

  const { docs } = useAppSelector(selectBusiness);

  const { data, isLoading, refetch } = useGetVendor({
    enabled: !!vendorId,
    vendorId: vendorId!,
    businessId: docs[0].businessID._id,
  });

  const [vendorData, setVendorData] = useState<SingleVendorType>();

  useEffect(() => {
    setVendorData(data);
  }, [data]);

  const [blackListedReason, setBlackListedReason] = useState('');

  const handleBlacklistUpdate = async (blacklisted: boolean, reason: string) => {
    setVendorData((prev: any) => {
      return {
        ...prev,
        blacklisted: blacklisted,
        blacklistedReason: reason,
      };
    });
  };

  const showName = (name: string) => {
    switch (name) {
      case 'BusinessDoc':
        return 'Business registration document';
      case 'Businessregistration':
        return 'Business registration document';
      case 'LicensesDoc':
        return 'Lincences document';
      case 'License':
        return 'Lincences document';
      case 'UtilityBillDoc':
        return ' Latest Utility bill';
      case 'Utilitybill':
        return ' Latest Utility bill';
      case 'IdentificationDoc':
        return 'Identification documents';
      case 'Proofofidentification':
        return 'Identification documents';
      default:
        break;
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center mt-24">
          <img src={Loader} className="w-32" alt="" />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between my-4">
            <Breadcrumb text="Vendors" subText={vendorData?.vendorName} />
            {vendorData?.active && (
              <div className="flex items-center gap-4">
                <Button
                  disabled={vendorData.blacklisted}
                  type="button"
                  variant="outline"
                  size="sm"
                  label="Edit"
                  className="bg-transparent border-[#0353A4]"
                  icon={<Icon name={Icons.EditPencil} />}
                  iconPosition="left"
                  iconLabelSpacing={5}
                  onClick={() => setShowEditVendorModal(true)}
                />
                <CheckPermissions requiredPermissions={['blacklist_vendor']}>
                  <Button
                    type="button"
                    variant="outline"
                    size="custom"
                    label={
                      vendorData?.blacklisted ? 'Remove Blacklist' : 'Blacklist vendor'
                    }
                    className="bg-[#AA1414] text-white px-5 py-3 text-base border-none"
                    onClick={() => {
                      setWantToBlackList(vendorData?.blacklisted ? true : false);
                      setShowBlackListModal(true);
                    }}
                  />
                </CheckPermissions>
              </div>
            )}

            {/* : (
                <CheckPermissions requiredPermissions={['blacklist_vendor']}>
                  <div className='flex items-center gap-4'>
                    <Button
                      type='button'
                      variant="primary"
                      size="custom"
                      label="Approve"
                        className="border-[#0353A4] bg-[#061A40] px-5 py-3 w-[9rem]"
                      onClick={() => setShowApproveSuccessModal(true)}
                    />
                      <Button
                        type='button'
                        variant="outline"
                        size="custom"
                        label='Decline'
                        className="bg-[#AA1414] text-white px-5 py-3 text-base w-[9rem] border-none"
                        onClick={() => setShowDeclineModal(true)}
                      />
                  </div>
                </CheckPermissions>
              )} */}
          </div>
          {vendorData?.blacklisted && (
            <div className="flex items-center justify-center mb-4 text-red-600">
              <h1>Vendor has been Blacklisted</h1>
              <p>Reason: {vendorData?.blacklistedReason}</p>
            </div>
          )}
          <div className="mb-5 rounded-md border border-[#ebedf2] bg-white p-4">
            <div className="flex flex-col sm:flex-row">
              <div className="mr-10 my-9">
                <img
                  src={avatar}
                  alt="avatar"
                  className="object-cover w-20 h-20 ml-3 rounded-full md:h-32 md:w-32"
                />
              </div>
              <div className="flex flex-col mt-10">
                <div className="flex flex-col pb-5 border-b">
                  <p>{vendorData?.vendorName}</p>
                  <div className="text-sm whitespace-nowrap">
                    <Status
                      status={
                        vendorData?.approved
                          ? 'Approved'
                          : vendorData?.blacklisted
                          ? 'Blacklisted'
                          : vendorData?.active
                          ? 'Active'
                          : vendorData?.vendorID.status === 'pending'
                          ? 'Pending'
                          : 'Not registered'
                      }
                      title={
                        vendorData?.approved
                          ? 'Approved'
                          : vendorData?.blacklisted
                          ? 'Blacklisted'
                          : vendorData?.active
                          ? 'Active'
                          : vendorData?.vendorID.status === 'pending'
                          ? 'Pending'
                          : 'Not registered'
                      }
                    />
                  </div>
                </div>
                <div className="grid flex-1 grid-cols-1 gap-40 mt-4 sm:grid-cols-3">
                  <div className="flex-2">
                    <p className="text-base font-normal text-black/30 font-inter">
                      Total transaction
                    </p>
                    <p className="text-3xl font-medium text-black font-inter">
                      {vendorData?.vendorID?.totalTransactions !== undefined
                        ? `₦${vendorData.vendorID.totalTransactions}`
                        : 'N/A'}
                    </p>
                  </div>

                  <div>
                    <p className="text-base font-normal text-black/30 font-inter">
                      Rating
                    </p>
                    <div className="flex items-center gap-2">
                      <Icon name={Icons.Rating} />
                      <span className="text-3xl font-medium text-black font-inter">
                        {vendorData?.vendorID?.rating ?? 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {vendorData && !vendorData?.approved && vendorData?.active && (
              <VendorApprovalFlow vendor={vendorData} getVendor={refetch} />
            )}
          </div>

          <div className="grid grid-cols-1 gap-5 mt-4 lg:grid-cols-3 xl:grid-cols-5">
            {/* Company Details  */}
            <div className="lg:col-span-1 xl:col-span-2 rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
              <div className="flex flex-col pl-4 mx-auto">
                <div className="text-left">
                  <p className="text-xl font-medium font-inter text-black/50">
                    Company contact
                  </p>
                </div>
                <div className="w-full my-4">
                  <div className="mt-0 border-b pb-9">
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-normal text-black">Email</span>
                      <span className="text-base font-normal text-right text-black">
                        {vendorData?.contactEmail}
                      </span>
                    </div>
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-medium text-black">
                        Phone number
                      </span>
                      <span className="text-base font-medium text-right text-black">
                        {vendorData?.contactPhone}
                      </span>
                    </div>
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-medium text-black">Address</span>
                      <span className="text-base font-medium text-right text-black">
                        {vendorData?.vendorID !== null ? (
                          <>
                            {' '}
                            {vendorData?.vendorID.addresses[0].address},{' '}
                            {vendorData?.vendorID.addresses[0].state}{' '}
                            {vendorData?.vendorID.addresses[0].country}
                          </>
                        ) : (
                          'Vendor is yet to to register'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col pl-4 mx-auto">
                <div className="text-left">
                  <p className="text-xl font-medium font-inter text-black/50">
                    Company details
                  </p>
                </div>
                <div className="w-full my-4">
                  <div className="mt-0">
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-normal text-black">RC number</span>
                      <span className="text-base font-normal text-right text-black">
                        {vendorData?.vendorID !== null ? (
                          <> {vendorData?.vendorID.rcNumber}</>
                        ) : (
                          'Vendor is yet to to register'
                        )}
                      </span>
                    </div>
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-medium text-black">
                        Industry type
                      </span>
                      <span className="text-base font-medium text-right text-black">
                        {vendorData?.vendorID !== null ? (
                          <> {vendorData?.vendorID.industryType}</>
                        ) : (
                          'Vendor is yet to to register'
                        )}
                      </span>
                    </div>
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-medium text-black">
                        Company size
                      </span>
                      <span className="text-base font-medium text-right text-black">
                        {vendorData?.vendorID !== null ? (
                          <> {vendorData?.vendorID.companySize}</>
                        ) : (
                          'Vendor is yet to to register'
                        )}
                      </span>
                    </div>
                    <div className="py-1.5 flex items-center justify-between">
                      <span className="text-base font-medium text-black">
                        Terms of Trade
                      </span>
                      <span className="text-base font-medium text-right text-black">
                        {vendorData?.vendorID !== null ? (
                          <> {vendorData?.termsOfTrade}</>
                        ) : (
                          'Vendor is yet to to register'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Bank Details */}
            <div className="lg:col-span-2 xl:col-span-3 rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
              <div className="flex flex-col">
                <div className="w-full mx-auto my-4">
                  <div className="pb-4 border-b">
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveTab(activeTab === 'Bank' ? '' : 'Bank')}
                    >
                      <p className="text-xl font-medium font-inter text-black/50">
                        Bank details
                      </p>
                      <Icon
                        name={activeTab === 'Bank' ? Icons.CaretUp : Icons.CaretDown}
                      />
                    </div>
                    <AnimatePresence>
                      {activeTab === 'Bank' && (
                        <DropDown>
                          <div className="mt-8">
                            {vendorData?.vendorID !== null
                              ? (vendorData?.vendorID?.bankInfo ?? []).map((bk) => (
                                  <div key={bk._id}>
                                    <div className="flex mb-4">
                                      <p className="w-1/4 text-base font-normal text-black font-inter">
                                        Bank Name
                                      </p>
                                      <p className="w-2/3 text-base font-medium text-black font-inter">
                                        {bk.bankName}
                                      </p>
                                    </div>
                                    <div className="flex mb-4">
                                      <p className="w-1/4 text-base font-normal text-black font-inter">
                                        Account Name
                                      </p>
                                      <p className="w-2/3 text-base font-medium text-black font-inter">
                                        {bk.accountName}
                                      </p>
                                    </div>
                                    <div className="flex mb-4">
                                      <p className="w-1/4 text-base font-normal text-black font-inter">
                                        Account Number
                                      </p>
                                      <p className="w-2/3 text-base font-medium text-black font-inter">
                                        {bk.accountNumber}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              : 'Vendor is yet to to register'}
                          </div>
                        </DropDown>
                      )}
                    </AnimatePresence>
                  </div>
                  <div className="py-6 border-b">
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveTab(activeTab === 'Rep' ? '' : 'Rep')}
                    >
                      <p className="text-xl font-medium font-inter text-black/50">
                        Representative details
                      </p>
                      <Icon
                        name={activeTab === 'Rep' ? Icons.CaretUp : Icons.CaretDown}
                      />
                    </div>
                    <AnimatePresence>
                      {activeTab === 'Rep' && (
                        <DropDown>
                          <div className="mt-8">
                            {vendorData?.vendorID !== null
                              ? (vendorData?.vendorID?.repsDetails ?? []).map(
                                  (rep: RepsDetail) => (
                                    <div key={rep._id}>
                                      <div className="flex justify-between pr-10 mb-4">
                                        <p className="text-base font-normal text-left text-black font-inter">
                                          Reps fullname
                                        </p>
                                        <p className="text-base font-medium text-right text-black font-inter">{`${rep.firstName} ${rep.lastName}`}</p>
                                      </div>
                                      <div className="flex justify-between pr-10 mb-4">
                                        <p className="text-base font-normal text-left text-black font-inter">
                                          Email
                                        </p>
                                        <p className="text-base font-medium text-right text-black font-inter">
                                          {rep.email}
                                        </p>
                                      </div>
                                      <div className="flex justify-between pr-10 mb-4">
                                        <p className="text-base font-normal text-left text-black font-inter">
                                          Position
                                        </p>
                                        <p className="text-base font-medium text-right text-black font-inter">
                                          {rep.position}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                )
                              : 'Vendor is yet to to register'}
                          </div>
                        </DropDown>
                      )}
                    </AnimatePresence>
                  </div>
                  <div className="py-6 border-b">
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() =>
                        setActiveTab(activeTab === 'Documents' ? '' : 'Documents')
                      }
                    >
                      <p className="text-xl font-medium font-inter text-black/50">
                        Documents
                      </p>
                      <Icon
                        name={activeTab === 'Documents' ? Icons.CaretUp : Icons.CaretDown}
                      />
                    </div>
                    <AnimatePresence>
                      {activeTab === 'Documents' && (
                        <DropDown>
                          <div className="mt-3">
                            <div>
                              {vendorData?.vendorID?.kybDocument.map(
                                (doc: KybDocument) => (
                                  <div className="flex flex-col w-5/12" key={doc._id}>
                                    <a
                                      href={doc.documentURL}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <UploadPreview
                                        fileName={doc.name}
                                        canBeDelete={false}
                                      />
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </DropDown>
                      )}
                    </AnimatePresence>
                  </div>

                  <div className="py-6 border-b">
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setActiveTab(activeTab === 'KYB' ? '' : 'KYB')}
                    >
                      <p className="text-xl font-medium font-inter text-black/50">
                        KYB Documents
                      </p>
                      <Icon
                        name={activeTab === 'Documents' ? Icons.CaretUp : Icons.CaretDown}
                      />
                    </div>
                    <AnimatePresence>
                      {activeTab === 'KYB' && (
                        <DropDown>
                          <div className="mt-3">
                            <div className="flex flex-col gap-2 ">
                              {vendorData?.vendorID?.kybDocument.map(
                                (doc: KybDocument) => (
                                  <a
                                    href={doc.documentURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={doc._id}
                                  >
                                    <div className="flex items-center gap-2">
                                      <Icon name={Icons.Attachment} />
                                      <p className="text-sm ">{doc.name}</p>
                                      <span className="font-medium font-inter text-lg text-blueTextColor">
                                        {showName(doc.name)}
                                      </span>
                                    </div>
                                  </a>
                                )
                              )}
                            </div>
                          </div>
                        </DropDown>
                      )}
                    </AnimatePresence>
                  </div>

                  <div className="py-6 border-b">
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() =>
                        setActiveTab(activeTab === 'Director' ? '' : 'Director')
                      }
                    >
                      <p className="text-xl font-medium font-inter text-black/50">
                        Director Details
                      </p>
                      <Icon
                        name={activeTab === 'Director' ? Icons.CaretUp : Icons.CaretDown}
                      />
                    </div>
                    <AnimatePresence>
                      {activeTab === 'Director' && (
                        <DropDown>
                          <div className="mt-3">
                            <div>
                              {vendorData?.vendorID?.directorsDetails.map(
                                (dir: DirectorsDetail) => (
                                  <div
                                    className="grid flex-1 grid-cols-1 gap-y-5 sm:grid-cols-2 mt-10 pb-6"
                                    key={dir?._id}
                                  >
                                    <div className="flex flex-col gap-2">
                                      <p className="text-sm font-medium text-black/40">
                                        Director Fullname
                                      </p>
                                      <span className="text-lg font-medium text-black font-inter">
                                        {dir?.fullName}
                                      </span>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                      <p className="text-sm font-medium text-black/40">
                                        ID Number
                                      </p>
                                      <span className="text-lg font-medium text-black font-inter">
                                        {dir?.number}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </DropDown>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showConfirmModal && (
        <EnhancedConfirmModal
          content={'Are you sure you want to approve this vendor ?'}
          onConfirm={() => {
            // setShowSuccessModal(true)
            setShowConfirmModal(false);
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Approve"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
        />
      )}

      {showBlacklistModal && (
        <EnhancedBlacklistModal
          isAreadyBlackList={wantToBlackList}
          onSuccess={() => handleBlacklistUpdate(!wantToBlackList, blackListedReason)}
          setBlackListedReason={setBlackListedReason}
          onCancel={() => setShowBlackListModal(false)}
          vendorId={vendorData?._id}
          businessId={docs[0].businessID._id}
        />
      )}

      {showEditVendorModal && (
        <EnhancedEditVendorModal
          onCancel={() => {
            setShowEditVendorModal(false);
          }}
          onSuccess={() => {
            setShowEditVendorModal(false);
            setShowSuccessModal(true);
          }}
          vendor={vendorData}
          businessId={docs[0].businessID._id}
        />
      )}
      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Changes have been successfully made"
          description="Your changes have been updated"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
      {showApproveSuccessModal && (
        <EnhancedSuccessModal
          title="Vendor approved successfully "
          description="This vendor has been approved"
          cancelText="Back"
          onCancel={() => {
            setShowApproveSuccessModal(false);
          }}
        />
      )}
      {showDeclineModal && (
        <EnhancedDeclineVendorModal
          onCancel={() => setShowDeclineModal(false)}
          vendorId={vendorData?._id}
          businessId={docs[0].businessID._id}
        />
      )}
    </div>
  );
}
