import { useEffect, useState } from 'react';
import ModalPortal from '../../ModalPortal';
import { Icon, Icons } from '../../Icon';
import Button from '../../Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '../../Form/TextField';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectAccountDetails,
  selectIsAuthenticated,
} from '../../../selectors/account-selector';
import { login } from '../../../thunks/account-thunk';
import { toast } from 'react-toastify';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  onSignUpClick: () => void;
};

export default function AddExistingAccountModal({ onCancel, onSignUpClick }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { user, error: loginError } = useAppSelector(selectAccountDetails);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
      password: Yup.string().required('Password is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const { email, password } = values;
      dispatch(
        login({
          email: email.trim(),
          password: password.trim(),
          dispatch,
          isAlreadyLogin: true,
        })
      );
    },
  });

  useEffect(() => {
    if (loginError) {
      formik.setSubmitting(false);
      setIsSubmitting(false);
      toast.error(loginError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginError, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!user) {
        onCancel();
        toast.error('Login Failed');
      }
    }
  }, [isAuthenticated, user]);

  return (
    <div>
      <ModalPortal>
        <div className="fixed top-0 z-50 flex h-screen w-screen items-center justify-center bg-[#000000] bg-opacity-60">
          <div className="flex flex-col items-end justify-end px-32">
            <div className="cursor-pointer" role="button" tabIndex={0} onClick={onCancel}>
              <Icon name={Icons.Close} />
            </div>
            <div className="mr-12 rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
              <div className="flex flex-col">
                <div className="py-5 text-left max-w-xss">
                  <p className="text-xl font-medium text-black font-inter">Add Account</p>
                  <p className="text-sm font-normal text-black font-inter">
                    Create a separate account with another email address
                  </p>
                </div>

                <div className="mx-auto w-full max-w-[440px]">
                  <form className="space-y-1" onSubmit={formik.handleSubmit}>
                    <TextField
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Email"
                      error={formik.touched.email ? formik.errors.email : ''}
                    />
                    <TextField
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Password"
                      showPasswordToggler
                      error={formik.touched.password ? formik.errors.password : ''}
                    />
                    <div>
                      <div className="mt-4">
                        <span className="flex items-center justify-end">
                          <Link to="/auth/forgot-password">
                            <span className="text-[#061A40] font-medium text-sm">
                              Forgot Password
                            </span>
                          </Link>
                        </span>
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        label="Login"
                        className="mt-10"
                        loading={formik.isSubmitting}
                        disabled={formik.isSubmitting || isSubmitting}
                      />
                    </div>
                  </form>
                  <div className="text-center text-[#00000066] text-xs mt-4 flex items-center justify-center">
                    Don't have an account?
                    <div
                      role="button"
                      tabIndex={0}
                      className="text-[#061A40] ml-1 cursor-pointer"
                      onClick={onSignUpClick}
                    >
                      Sign Up
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalPortal>
    </div>
  );
}
