import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectPermission } from '../../selectors/business-selector';

function CheckPermissions({ requiredPermissions, children }: any) {
  const permission = useAppSelector(selectPermission);
  let permissionList: string[] = [];
  if (!permission.permissions.toString()) {
    permissionList = JSON.parse(sessionStorage.getItem('userPermissions') || '[]');
  } else {
    permissionList = permission.permissions;
  }

  const arePermissionsAllowed = [...requiredPermissions, '*'].some((permission: any) =>
    permissionList.includes(permission)
  );

  if (arePermissionsAllowed) {
    return <>{children}</>; // Render the children if permissions are allowed
  } else {
    return null; // Optionally render an alternative UI if permissions are not allowed
  }
}

export default CheckPermissions;
