import { useQuery } from '@tanstack/react-query';
import { getDashboardStats } from '../../service/dashboard';
import { logout } from '../../thunks/account-thunk';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';

export const useGetDashboardStats = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(['dashboard'], () => getDashboardStats(businessId), {
    refetchOnWindowFocus: false,
    enabled: !!businessId,
    onError: (error: Error) => {
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};
