import React from 'react';
import '../style/index.css';
// import '../style/animate.css'
import '../style/style.css';
// import '../style/perfect-scrollbar.min.css'
import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <div className="w-full relative overflow-x-hidden font-inter text-sm font-normal antialiased">
      <div className="w-full main-container min-h-screen text-black">
        <div className="relative flex flex-col min-h-screen items-center justify-center px-6 py-10 sm:px-16">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
