import { useEffect } from 'react';
import { BsUpload } from 'react-icons/bs';

interface Props {
  setBusinessDoc: React.Dispatch<React.SetStateAction<File | null>>;
  setUtilityBillDoc: React.Dispatch<React.SetStateAction<File | null>>;
  setIdentificationDoc: React.Dispatch<React.SetStateAction<File | null>>;
  setLicensesDoc: React.Dispatch<React.SetStateAction<File | null>>;
  businessDoc: File | null;
  licensesDoc: File | null;
  utilityBillDoc: File | null;
  identificationDoc: File | null;
  businessDocName: string;
  utilityBillDocName: string;
  identificationDocName: string;
  licensesDocName: string;
  setBusinessDocName: React.Dispatch<React.SetStateAction<string>>;
  setUtilityBillDocName: React.Dispatch<React.SetStateAction<string>>;
  setIdentificationDocName: React.Dispatch<React.SetStateAction<string>>;
  setLicensesDocName: React.Dispatch<React.SetStateAction<string>>;
  emptyFields: any;
}
function Docs({
  setBusinessDoc,
  setUtilityBillDoc,
  setIdentificationDoc,
  setLicensesDoc,
  businessDoc,
  licensesDoc,
  utilityBillDoc,
  identificationDoc,
  businessDocName,
  utilityBillDocName,
  identificationDocName,
  licensesDocName,
  setBusinessDocName,
  setUtilityBillDocName,
  setIdentificationDocName,
  setLicensesDocName,
  emptyFields,
}: Props) {
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFileState: React.Dispatch<React.SetStateAction<File | null>>,
    setFileNameState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const newName = event.target.name + '.' + file.name.split('.').pop(); // create new file name
    const newFile = new File([file], newName, { type: file.type }); // create new file object with updated name
    setFileState(newFile);
    setFileNameState(file.name);
  };
  useEffect(() => {
    if (businessDoc !== null) {
      setBusinessDocName(businessDoc.name);
    }
    if (utilityBillDoc !== null) {
      setUtilityBillDocName(utilityBillDoc.name);
    }
    if (identificationDoc !== null) {
      setIdentificationDocName(identificationDoc.name);
    }
    if (licensesDoc !== null) {
      setLicensesDocName(licensesDoc.name);
    }
    // eslint-disable-next-line
  }, [businessDoc, utilityBillDoc, identificationDoc, licensesDoc]);
  return (
    <div className="form-body">
      <div className="tag">
        <p>KYB : Fill in the details below</p>
      </div>
      <div className="cardt">
        <h3>Manual KYB:</h3>
        <p>Enter the folowing details to verify your account</p>
      </div>
      <div className="cardX">
        <div className="lef">
          <h4>Business registration document</h4>
          {/* <p>Lorem ipsum dolor sit amet, </p> */}
        </div>
        <div
          className={
            emptyFields.includes('Business Document') && businessDoc === null
              ? 'rig active'
              : 'rig'
          }
        >
          <input
            type="file"
            name="BusinessDoc"
            id="biz-reg"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => handleFileChange(e, setBusinessDoc, setBusinessDocName)}
          />
          <label htmlFor="biz-reg">
            <div className="butz">
              <BsUpload />
              <p>Upload</p>
            </div>
          </label>
          <p className="sel">{businessDocName}</p>
        </div>
      </div>
      <div className="cardX">
        <div className="lef">
          <h4> Latest Utility bill</h4>
          <p>Power bills,waste, Water, etc </p>
        </div>
        <div
          className={
            emptyFields.includes('Utility bill Document') && utilityBillDoc === null
              ? 'rig active'
              : 'rig'
          }
        >
          <input
            type="file"
            name="UtilityBillDoc"
            id="utility"
            accept=".jpg, .jpeg, .png"
            onChange={(e) =>
              handleFileChange(e, setUtilityBillDoc, setUtilityBillDocName)
            }
          />
          <label htmlFor="utility">
            <div className="butz">
              <BsUpload />
              <p>Upload</p>
            </div>
          </label>
          <p className="sel">{utilityBillDocName}</p>
        </div>
      </div>
      <div className="cardX">
        <div className="lef">
          <h4>Identification documents</h4>
          {/* <p>Lorem ipsum dolor sit amet </p> */}
        </div>
        <div
          className={
            emptyFields.includes('Director Identification Document') &&
            identificationDoc === null
              ? 'rig active'
              : 'rig'
          }
        >
          <input
            type="file"
            name="IdentificationDoc"
            id="identification"
            accept=".jpg, .jpeg, .png"
            onChange={(e) =>
              handleFileChange(e, setIdentificationDoc, setIdentificationDocName)
            }
          />
          <label htmlFor="identification">
            <div className="butz">
              <BsUpload />
              <p>Upload</p>
            </div>
          </label>
          <p className="sel">{identificationDocName}</p>
        </div>
      </div>
      <div className="cardX">
        <div className="lef">
          <h4>Lincence document</h4>
          {/* <p>Lorem ipsum dolor sit amet, </p> */}
        </div>
        <div
          className={
            emptyFields.includes('Licence Document') && licensesDoc === null
              ? 'rig active'
              : 'rig'
          }
        >
          <input
            type="file"
            name="LicensesDoc"
            id="licen"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => handleFileChange(e, setLicensesDoc, setLicensesDocName)}
          />
          <label htmlFor="licen">
            <div className="butz">
              <BsUpload />
              <p>Upload</p>
            </div>
          </label>
          <p className="sel">{licensesDocName}</p>
        </div>
      </div>
    </div>
  );
}

export default Docs;
