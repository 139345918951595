import { useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../../../../Components/Form/Button';
import TextField from '../../../../../Components/Form/TextField';
import CustomSelect from '../../../../../Components/Form/CustomSelect';
import { BusinessId, ContactDto } from '../../../../../types/business.type';
import IndustryList from '../../../../../Data/Industries';

type Props = {
  onSave: (values: ContactDto) => void;
  business: BusinessId;
};

export default function EditCompanyInfo({ onSave, business }: Readonly<Props>) {
  const addresses = useMemo(() => {
    if (business?.addresses.length) {
      return business?.addresses.map((ad) => ({
        address: ad.address ?? '',
        country: ad.country ?? '',
        state: ad.state ?? '',
        postalCode: ad.postalCode ?? '',
        addressType: ad.addressType ?? '',
      }));
    } else {
      return [
        {
          address: '',
          country: '',
          state: '',
          postalCode: '',
          addressType: '',
        },
      ];
    }
  }, [business?.addresses]);

  const formik = useFormik({
    initialValues: {
      type: business.type,
      name: business?.name ?? '',
      email: business?.email ?? '',
      phone: business?.phone ?? '',
      rcNumber: business?.rcNumber ?? '',
      industryType: business?.industryType ?? '',
      companySize: business?.companySize ?? '',
      companyAddresss: addresses,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Company name is required').nullable(),
      email: Yup.string()
        .email('Company Email')
        .required('Company email is required')
        .nullable(),
      phone: Yup.string()
        .matches(/^\d+$/, 'Phone number must be numeric')
        .required('Phone number is required')
        .nullable(),
      rcNumber: Yup.string()
        .matches(/^\d+$/, 'RC number must be numeric')
        .required('RC number is required')
        .nullable(),
      industryType: Yup.string().required('Industry type is required').nullable(),
      companySize: Yup.string().required('Company size is required').nullable(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      onSave(values);
    },
  });

  return (
    <form className="mt-10" onSubmit={formik.handleSubmit}>
      <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
        <TextField
          name="name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Company Name"
          error={formik.touched.name ? formik.errors.name : ''}
          className="bg-[#FFFFFF8C]"
        />
        <TextField
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Company Email"
          error={formik.touched.email ? formik.errors.email : ''}
          className="bg-[#FFFFFF8C]"
        />
        <TextField
          name="phone"
          type="number"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Phone Number"
          error={formik.touched.phone ? formik.errors.phone : ''}
          className="bg-[#FFFFFF8C]"
        />
        <TextField
          name="rcNumber"
          type="number"
          value={formik.values.rcNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="RC Number"
          error={formik.touched.rcNumber ? formik.errors.rcNumber : ''}
          className="bg-[#FFFFFF8C]"
        />
        <CustomSelect
          options={IndustryList.map((industry) => ({
            value: industry,
            label: industry,
          }))}
          selectedOption={{
            value: formik.values.industryType,
            label: formik.values.industryType,
          }}
          handleOptionChange={(option) => {
            formik.setFieldValue('industryType', option?.value);
          }}
          label="Industry Type"
          name="industryType"
          isSearchable
          className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
          error={formik.touched.industryType ? formik.errors.industryType : ''}
        />
        <CustomSelect
          options={[
            { value: '1-100', label: '1-100' },
            { value: '101 - 200', label: '101 - 200' },
            { value: '201 - 500', label: '201 - 500' },
            { value: '501 - 1000', label: '501 - 1000' },
            { value: '> 1000', label: '> 1000' },
          ]}
          selectedOption={{
            value: formik.values.companySize,
            label: formik.values.companySize,
          }}
          handleOptionChange={(option) => {
            formik.setFieldValue('companySize', option?.value);
          }}
          label="Company Size"
          name="companySize"
          isSearchable
          className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
          error={formik.touched.companySize ? formik.errors.companySize : ''}
        />
      </div>
      <Button
        type="submit"
        variant="primary"
        size="md"
        label="Save Changes"
        className="mt-10"
      />
    </form>
  );
}
