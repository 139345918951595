import { FaMinus, FaPlus } from 'react-icons/fa';

function Reps({ reps, setReps, emptyFields, disabled }: any) {
  const handleAddressChange = (event: any, index: any) => {
    const data: any = [...reps];
    data[index][event.target.name] = event.target.value;
    setReps(data);
  };
  const addAddressFields = () => {
    const object = {
      firstName: '',
      lastName: '',
      email: '',
      position: '',
    };
    setReps([...reps, object]);
  };
  const removeAddressFields = (index: any) => {
    const data = [...reps];
    data.splice(index, 1);
    setReps(data);
  };
  return (
    <div className="form-body">
      <div className="tag">
        <p>Representative Information</p>
      </div>
      {reps.map((form: any, index: any) => (
        <div key={index + 1} className="seti">
          <div className="card">
            <p>Rep First Name</p>
            <input
              type="text"
              id=""
              disabled={disabled}
              name="firstName"
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.firstName}
              className={
                emptyFields.includes('Fill at least one Rep Information completely') &&
                reps[index].firstName === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className="card">
            <p>Rep Last Name</p>
            <input
              type="text"
              id=""
              disabled={disabled}
              name="lastName"
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.lastName}
              className={
                emptyFields.includes('Fill at least one Rep Information completely') &&
                reps[index].lastName === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className="card">
            <p>Rep Email</p>
            <input
              type="email"
              id=""
              disabled={disabled}
              name="email"
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.email}
              className={
                emptyFields.includes('Fill at least one Rep Information completely') &&
                reps[index].email === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className="card">
            <p>Rep Position</p>
            <input
              type="text"
              id=""
              disabled={disabled}
              name="position"
              onChange={(event) => handleAddressChange(event, index)}
              defaultValue={form.position}
              className={
                emptyFields.includes('Fill at least one Rep Information completely') &&
                reps[index].position === ''
                  ? 'active'
                  : ''
              }
            />
            {!disabled && (
              <div className="anothes">
                <div className="remove">
                  <FaMinus onClick={() => removeAddressFields(index)} />
                  <p onClick={() => removeAddressFields(index)}>Remove</p>
                </div>
                <div className="addx">
                  <FaPlus onClick={addAddressFields} />
                  <p onClick={addAddressFields}>Add another</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {reps.length === 0 && (
        <>
          {!disabled && (
            <div className="seti">
              <div className="card" style={{ width: '100%' }}>
                <div className="anothes">
                  <div className="remove"></div>
                  <div className="addx">
                    <FaPlus onClick={addAddressFields} />
                    <p onClick={addAddressFields}>Add another</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Reps;
