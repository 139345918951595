import { AxiosError } from 'axios';
import { baseService } from '../hooks/queries-and-mutations';
import { errorHandler, getToken } from '.';
import { DashboardTypes } from '../types/dashboard.type';

export const getDashboardStats = async (businessId: string): Promise<DashboardTypes> => {
  try {
    const response = await baseService.get(`/dashboard/${businessId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
