import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../Form/TextField';
import CustomSelect from '../../Form/CustomSelect';
import Button from '../../Form/Button';
import UploadPreview from '../../Form/UploadPreview';
import { useUpdateVendor } from '../../../hooks/queries-and-mutations/vendor';
import { SingleVendorType } from '../../../types/vendor.type';
import { InfoModal } from '../InfoModal';
import { toast } from 'react-toastify';
import UploadFile from '../../Form/UploadFile';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  businessId: string;
  vendor: SingleVendorType;
};

export default function EditVendorModal({ onCancel, businessId, vendor }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutate, isSuccess, isError } = useUpdateVendor();

  const formik = useFormik({
    initialValues: {
      vendorName: vendor.vendorName,
      vendorEmail: vendor.contactEmail,
      vendorType: vendor.type,
      goodType: vendor.goodsSupplied,
      termOfTrade: vendor.termsOfTrade,
      contactName: vendor.contactName,
      contactNumber: vendor.contactPhone,
      newTermsOfTrade: vendor.termsOfTrade,
      contractDocs: [],
    },
    validationSchema: Yup.object({
      vendorName: Yup.string().required('Vendor name is required').nullable(),
      vendorEmail: Yup.string()
        .email('Invalid Email')
        .required('Email is required')
        .nullable(),
      vendorType: Yup.string().required('Please select a vendor type').nullable(),
      goodType: Yup.string().required('Good supplied is required').nullable(),
      termOfTrade: Yup.string().required('Terms of trade is required').nullable(),
      contactName: Yup.string().required('Contact name is required').nullable(),
      // contactNumber: Yup.string().required("Contact number is required").nullable(),
      newTermsOfTrade: Yup.string().when('termOfTrade', {
        is: 'others',
        then: Yup.string().nullable().required('Please tell us your terms of trade'),
        otherwise: Yup.string().nullable(),
      }),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsSubmitting(true);
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: { [key: string]: any }) => {
    let terms = values.termOfTrade;
    if (values.termOfTrade === 'others') {
      terms = values.newTermsOfTrade;
    }

    const formData = new FormData();
    formData.append('contactName', values.contactName);
    formData.append('contactEmail', values.vendorEmail.toLocaleLowerCase());
    formData.append('contactPhone', values.contactNumber);
    formData.append('goodsSupplied', values.goodType);
    formData.append('termsOfTrade', terms);
    values.contractDocs.forEach((file: any) => {
      formData.append('contract', file);
    });
    const formDataValues: { [key: string]: FormDataEntryValue } = {};

    Array.from(formData.entries()).forEach(([key, value]) => {
      formDataValues[key] = value;
    });

    const data = {
      contactName: values.contactName,
      contactEmail: values.vendorEmail.toLocaleLowerCase(),
      contactPhone: values.contactNumber,
      goodsSupplied: values.goodType,
      termsOfTrade: terms,
    };
    let submitData = null;
    if (values.contractDocs.length > 0) {
      submitData = formData;
    } else {
      submitData = data;
    }
    mutate({ vendorId: vendor._id, businessId, payload: submitData });
  };

  const handleFileDelete = (fileName: string) => {
    const newFile = formik.values.contractDocs.filter(
      (file: File) => file.name !== fileName
    );
    formik.setFieldValue('contractDocs', newFile);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      toast.success('Vendor successfully updated');
      onCancel();
    } else if (isError) {
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  return (
    <InfoModal width="w-[45%]" className="h-fit" onClose={onCancel}>
      <div className="rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="py-5 pb-3 text-left border-b max-w-xss">
            <p className="text-xl font-medium text-black font-inter">Edit Vendor</p>
            <p className="text-sm font-normal font-inter text-black/40">
              Search or invite vendor if they are not registered yet.
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <form className="space-y-0.5" onSubmit={formik.handleSubmit}>
              <TextField
                name="vendorName"
                type="text"
                value={formik.values.vendorName}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Vendor Name"
                disabled
                error={formik.touched.vendorName ? formik.errors.vendorName : ''}
                className="bg-white"
              />
              <div className="grid flex-1 grid-cols-1 pt-5 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="vendorEmail"
                  type="email"
                  disabled
                  value={formik.values.vendorEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Vendor Email"
                  error={formik.touched.vendorEmail ? formik.errors.vendorEmail : ''}
                  className="bg-white"
                />
                <CustomSelect
                  options={[
                    { value: 'Supplier', label: 'Supplier' },
                    { value: 'Buyer', label: 'Buyer' },
                  ]}
                  selectedOption={{
                    value: formik.values.vendorType,
                    label: formik.values.vendorType,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('vendorType', option?.value);
                  }}
                  label="Vendor Type"
                  name="vendorType"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.touched.vendorType ? formik.errors.vendorType : ''}
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="goodType"
                  type="text"
                  value={formik.values.goodType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label={
                    formik.values.vendorType === 'Buyer' ? 'Good Bought' : 'Good Supplied'
                  }
                  error={formik.touched.goodType ? formik.errors.goodType : ''}
                  className="bg-white"
                />
                <CustomSelect
                  options={[
                    { value: '30 days', label: '30 days' },
                    { value: '60 days', label: '60 days' },
                    { value: '90 days', label: '90 days' },
                    { value: 'others', label: 'others' },
                  ]}
                  selectedOption={{
                    value: formik.values.termOfTrade,
                    label: formik.values.termOfTrade,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('termOfTrade', option?.value);
                  }}
                  label="Terms of Trade"
                  name="termOfTrade"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.touched.termOfTrade ? formik.errors.termOfTrade : ''}
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="contactName"
                  type="text"
                  value={formik.values.contactName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Contact Name"
                  error={formik.touched.contactName ? formik.errors.contactName : ''}
                  className="bg-white"
                />
                <TextField
                  name="contactNumber"
                  type="text"
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Contact Number"
                  error={formik.touched.contactNumber ? formik.errors.contactNumber : ''}
                  className="bg-white"
                />
                {formik.values.termOfTrade === 'others' && (
                  <TextField
                    name="newTermsOfTrade"
                    type="text"
                    value={formik.values.newTermsOfTrade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Type terms of trade"
                    error={
                      formik.touched.newTermsOfTrade ? formik.errors.newTermsOfTrade : ''
                    }
                    className="bg-white"
                  />
                )}
              </div>

              {vendor.contractDocs?.length > 0 && (
                <div className="mt-2">
                  <p className="mb-2 text-lg font-semibold"></p>
                  {vendor.contractDocs.map((doc: string, index: number) => (
                    <div
                      key={index}
                      className="flex items-center justify-between py-2 mb-8 border-b border-gray-200"
                    >
                      <a
                        href={doc}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline underline text-[20px]"
                      >{`Contract Document`}</a>
                    </div>
                  ))}
                </div>
              )}

              <UploadFile
                acceptFile={['.jpeg', '.png', '.jpg', '.pdf']}
                isMultiple
                label=""
                fileName="contractDocs"
                getMultipleFile={(files) => {
                  formik.setFieldValue('contractDocs', files);
                }}
              />
              {formik.values.contractDocs?.length > 0 && (
                <div className="mt-2">
                  {formik.values.contractDocs.map((file: File) => (
                    <UploadPreview
                      key={file.name}
                      fileName={file.name}
                      removeFile={() => handleFileDelete(file.name)}
                    />
                  ))}
                </div>
              )}

              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label="Submit"
                  className="mt-12"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || isSubmitting}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
