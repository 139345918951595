import React from 'react';
import { IoIosMenu } from 'react-icons/io';
import Plugged from '../../Assests/plug.webp';
import { Link } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  return (
    <div>
      <Link to="/">
        <span className="text-[35px] absolute left-[20px] top-[20px] cursor-pointer ">
          {<IoIosMenu />}{' '}
        </span>
      </Link>
      <div className="flex items-center justify-center h-[100vh] w-full">
        <div className="flex items-center justify-around gap-[10px] w-full">
          <div className="">
            <img src={Plugged} alt="img" />
          </div>
          <div className="flex flex-col justify-center items-center gap-3 w-fit">
            <h1 className="scroll-m-20 font-bold tracking-normal text-[120px] text-blueTextColor">
              404{' '}
            </h1>
            <h2 className="scroll-m-20 text-[30px]  font-semibold tracking-tight">
              Page Not Found
            </h2>
            <p className="flex flex-col items-center text-center text-blueTextColor2">
              <span>We are sorry the page you requested could not be found</span>
              <span>Please go back to the homepage.</span>
            </p>
            <Link to="/">
              <button className="py-2 px-4 rounded-lg bg-primary text-white text-base">
                Go Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
