import React, { FC } from 'react';

interface IModalProps {
  children: React.ReactNode;
}

export const Modal: FC<IModalProps> = ({ children }) => (
  <div className="fixed insert-x-0 bottom-0 top-24 z-50 flex max-h-full w-full flex-col items-center justify-center bg-black/70 overflow-y-auto p-7 md:inset-0 md:p-4">
    <div className="md:justify-center overflow-x-hidden md:h-fit h-full w-full">
      {children}
    </div>
  </div>
);
