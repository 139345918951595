import { AxiosError } from 'axios';
import { baseService, errorHandler, getToken } from '.';
import { InvoiceType, SingleInvoice } from '../types/invoice.type';

export const getInvoices = async (
  businessId: any,
  type: any,
  page: any
): Promise<InvoiceType> => {
  try {
    let query = '';
    if (type) {
      query = `${type}`;
    }
    const response = await baseService.post(
      `invoice/${businessId}/fetch/${query}?limit=10&page=${page}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getInvoice = async (
  businessId: any,
  invoiceId: any
): Promise<SingleInvoice> => {
  try {
    const response = await baseService.get(`invoice/${businessId}/${invoiceId}/fetch`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const deleteInvoice = async (
  businessId: string,
  invoiceId: string
): Promise<void> => {
  try {
    const response = await baseService.delete(
      `invoice/${businessId}/delete?id=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getInvoicePaymentProof = async (
  businessId: any,
  invoiceId: any
): Promise<SingleInvoice> => {
  try {
    const response = await baseService.get(
      `invoice/${businessId}/payment-proof/fetch?id=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const submitPaymentProof = async (
  businessId: any,
  invoiceId: any,
  payload: any
): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/pay?id=${invoiceId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const confirmInvoicePaymentProof = async (
  businessId: any,
  referenceId: any
): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/payment-proof/${referenceId}/confirm`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const verifyInvoicePayment = async (
  businessId: any,
  payemntId: any
): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/payment-proof/${payemntId}/verify`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createInvoice = async (
  businessId: any,
  payload: any,
  status: any
): Promise<any> => {
  try {
    const response = await baseService.post(
      `invoice/${businessId}/create?draft=${status}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateInvoice = async (
  businessId: string,
  updatedPayload: any,
  invoiceId: string
): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/update?id=${invoiceId}`,
      updatedPayload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const approveInvoice = async (businessId: any, invoiceId: any): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/approve?id=${invoiceId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const declineInvoice = async (
  businessId: any,
  payload: any,
  invoiceId: any
): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/decline?id=${invoiceId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const downloadInvoice = async (
  businessId: string,
  invoiceId: string
): Promise<any> => {
  try {
    const response = await baseService.get(
      `invoice/${businessId}/${invoiceId}/download`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const deleteAnInvoice = async (
  businessId: string,
  invoiceId: string
): Promise<any> => {
  try {
    const response = await baseService.delete(
      `invoice/${businessId}/delete?id=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const sendRemainder = async (
  businessId: string,
  invoiceId: string,
  payload: any
): Promise<any> => {
  try {
    const response = await baseService.post(
      `invoice/${businessId}/reminder?id=${invoiceId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const markInvoice = async (
  businessId: string,
  invoiceId: string,
  payload: any
): Promise<any> => {
  try {
    const response = await baseService.put(
      `invoice/${businessId}/paid?id=${invoiceId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const payForInvoice = async (
  businessId: string,
  reference: string
): Promise<any> => {
  try {
    const response = await baseService.post(
      `/${businessId}/payment-proof/${reference}/verify`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
