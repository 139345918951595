import { AxiosError } from 'axios';
import { baseService, errorHandler, getToken } from '.';
import { DepartmentType, PermissionType, RoleType } from '../types/setting.type';
import { ApprovalType } from '../types/approval.type';

export const getDepartment = async (
  businessId: any,
  page: any
): Promise<DepartmentType> => {
  try {
    const response = await baseService.get(
      `/department/${businessId}/fetch?limit=10&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createDepartment = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.post(`/department/${businessId}/create`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateDepartment = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.put(`/department/${businessId}/update`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getPermissionList = async (): Promise<PermissionType[]> => {
  try {
    const response = await baseService.get(`/auth/permissions`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getRole = async (businessId: any, page: any): Promise<RoleType> => {
  try {
    const response = await baseService.get(
      `/role/${businessId}/fetch?limit=10&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createRole = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.post(`/role/${businessId}/create`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateRole = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.put(`/role/${businessId}/update`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getPerson = async (
  businessId: any,
  params = '?not_on=payable'
): Promise<ApprovalType> => {
  try {
    const response = await baseService.post(
      `/business/${businessId}/members${params}`,
      {
        permissions: [
          'update_business',
          'view_business_member',
          'update_business_member',
          'create_business_role',
          'update_business_role',
          'delete_business_role',
          'create_business_department',
          'update_business_department',
          'view_vendors',
          'blacklist_vendor',
          'delete_vendor',
          'invite_vendors',
          'create_invoice',
          'approve_decline_invoice',
          'pay_invoice',
          'mark_invoice_paid',
          'view_invoice',
          'create_credit_request',
          'approve_supplier_finance_request',
          'approve_credit_request',
          'view_credit_request',
          'repay_credit',
          'view_dashboard',
          '*',
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getApproval = async (businessId: any): Promise<ApprovalType> => {
  try {
    const response = await baseService.post(
      `/business/${businessId}/members`,
      {
        permissions: [
          'update_business',
          'view_business_member',
          'update_business_member',
          'create_business_role',
          'update_business_role',
          'delete_business_role',
          'create_business_department',
          'update_business_department',
          'view_vendors',
          'blacklist_vendor',
          'delete_vendor',
          'invite_vendors',
          'create_invoice',
          'approve_decline_invoice',
          'pay_invoice',
          'mark_invoice_paid',
          'view_invoice',
          'create_credit_request',
          'approve_supplier_finance_request',
          'approve_credit_request',
          'view_credit_request',
          'repay_credit',
          'view_dashboard',
          '*',
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const togglePersonToApproval = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.patch(
      `/business/${businessId}/approvals`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
