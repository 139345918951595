import { useCallback, useMemo, useState } from 'react';
import { Icon, Icons } from '../../../Components/Icon';
import TextField from '../../../Components/Form/TextField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomSelect from '../../../Components/Form/CustomSelect';
import Button from '../../../Components/Form/Button';
import UploadPreview from '../../../Components/Form/UploadPreview';
import { useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { Director, FormDto, KYBDto } from '../../../types/business.type';
import { useGetBanks } from '../../../hooks/queries-and-mutations/business';
import { getBankDetails } from '../../../service/business';
import { isEmptyFile } from '../../../utils';
import { selectOnboarding } from '../../../selectors/onboarding-selector';
import FileUpload from '../../../Components/Form/FileUpload';

type Props = {
  onSubmit: (values: KYBDto) => void;
  values: FormDto | null;
};

export default function KYB({ onSubmit, values }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const business = useAppSelector(selectOnboarding);
  const [loadingSpinners, setLoadingSpinners] = useState<boolean[]>([]);
  const navigate = useNavigate();

  const { data: bankList } = useGetBanks();

  const directors = useMemo(() => {
    if (values && (values?.directors ?? []).length) {
      return values?.directors.map((dirc) => ({
        fullName: dirc.fullName,
        number: dirc.number,
        type: dirc.type,
      }));
    } else {
      return [
        {
          fullName: '',
          number: '',
          type: '',
        },
      ];
    }
  }, [values]);

  const representatives = useMemo(() => {
    if (values && (values?.representatives ?? []).length) {
      return values?.representatives.map((rep) => ({
        firstName: rep.firstName,
        lastName: rep.lastName,
        email: rep.email,
        position: rep.position,
      }));
    } else {
      return [
        {
          firstName: '',
          lastName: '',
          email: '',
          position: '',
        },
      ];
    }
  }, [values]);

  const banks = useMemo(() => {
    if (values && (values?.banks ?? []).length) {
      return values?.banks.map((bk) => ({
        bank: bk.bank,
        accountNumber: bk.accountNumber,
        accountName: bk.accountName,
      }));
    } else {
      return [
        {
          bank: { value: '', label: '' },
          accountNumber: '',
          accountName: '',
        },
      ];
    }
  }, [values]);

  const formik = useFormik({
    initialValues: {
      businessFile: !isEmptyFile(business.businessFile!) ? business.businessFile : null,
      utilityFile: !isEmptyFile(business.utilityFile!) ? business.utilityFile : null,
      identificationFile: !isEmptyFile(business.identificationFile!)
        ? business.identificationFile
        : null,
      licenseFile: !isEmptyFile(business.licenseFile!) ? business.licenseFile : null,
      directors,
      representatives,
      banks,
    },
    validationSchema: Yup.object({
      businessFile: Yup.string().required('Business registration is required').nullable(),
      utilityFile: Yup.string().required('Utility bill is required').nullable(),
      identificationFile: Yup.string()
        .required('Proof of identification is required')
        .nullable(),
      licenseFile: Yup.string().required('License is required').nullable(),
      directors: Yup.array().of(
        Yup.object().shape({
          fullName: Yup.string().required('Director name is required').nullable(),
          number: Yup.string().required('ID Number is required').nullable(),
          type: Yup.string().required('Type is required').nullable(),
        })
      ),
      representatives: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string()
            .required('Representative firstname is required')
            .nullable(),
          lastName: Yup.string()
            .required('Representative lastname is required')
            .nullable(),
          email: Yup.string()
            .email('Representative Email')
            .required('Representative Email is required')
            .nullable(),
          position: Yup.string()
            .required('Representative Position is required')
            .nullable(),
        })
      ),
      banks: Yup.array().of(
        Yup.object().shape({
          bank: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable()
            .required('Please select a bank'),
          accountNumber: Yup.string().required('Account number is required').nullable(),
          accountName: Yup.string().required('Account name is required').nullable(),
        })
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      try {
        onSubmit(values); // Await form submission
        resetForm();
        navigate('/onboarding/preview'); // Navigate to the next step
      } catch (error) {
        console.error('Form submission error:', error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const addMoreDirector = () => {
    formik.values.directors.push({
      fullName: '',
      number: '',
      type: '',
    });
    formik.setFieldValue('directors', [...formik.values.directors]);
  };

  const addMoreRepresentative = () => {
    formik.values.representatives.push({
      firstName: '',
      lastName: '',
      email: '',
      position: '',
    });
    formik.setFieldValue('representatives', [...formik.values.representatives]);
  };

  const addMoreBank = () => {
    formik.values.banks.push({
      bank: { value: '', label: '' },
      accountNumber: '',
      accountName: '',
    });
    formik.setFieldValue('banks', [...formik.values.banks]);
  };

  const deleteDirector = () => {
    formik.values.directors.pop();
    formik.setFieldValue('directors', [...formik.values.directors]);
  };
  const deleteRepresentative = () => {
    formik.values.representatives.pop();
    formik.setFieldValue('representatives', [...formik.values.representatives]);
  };
  const deleteBank = () => {
    formik.values.banks.pop();
    formik.setFieldValue('banks', [...formik.values.banks]);
  };

  const handleGetBankDetails = useCallback(
    async (index: number) => {
      if (
        formik.values.banks[index].accountNumber.toString().length === 10 &&
        formik.values.banks[index].bank.value
      ) {
        const payload = {
          bankCode: formik.values.banks[index].bank.value,
          accountNumber: formik.values.banks[index].accountNumber.toString(),
        };
        const bank = await getBankDetails(payload);
        formik.setFieldValue(`banks[${index}].accountName`, bank.acccountName);
        setLoadingSpinners((prev) => {
          const stopLoading = [...prev];
          stopLoading[index] = false;
          return stopLoading;
        });
      }
    },
    [formik]
  );

  const handleGoBack = () => {
    navigate('/onboarding/contact'); // Go back to the contact page
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} autoComplete="false" autoCorrect="false">
        <div className="mb-8">
          <div className="border-b border-[#0000000D] pb-2">
            <h2 className="text-xl text-[#263238] font-semibold">Document Upload</h2>
            <p className="text-base font-normal font-inter text-[#00000066]">
              Upload company document below
            </p>
          </div>

          <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
            {!formik.values.businessFile ? (
              <FileUpload
                error={
                  formik.errors.businessFile ? 'Business registration is required' : ''
                }
                label="Business Registration"
                fileName={formik.values.businessFile ?? ''}
                getFile={(file) => {
                  formik.setFieldValue('businessFile', file);
                }}
                requiredIndicator
              />
            ) : (
              <UploadPreview
                fileName={formik.values.businessFile?.name ?? ''}
                label="Business Registration"
                removeFile={() => {
                  formik.setFieldValue('businessFile', '');
                }}
                requiredIndicator
              />
            )}
            {!formik.values.utilityFile ? (
              <FileUpload
                error={formik.errors.utilityFile ? 'Utility bill is required' : ''}
                label="Utility Bill"
                fileName="Utilitybill"
                getFile={(file) => {
                  formik.setFieldValue('utilityFile', file);
                }}
                requiredIndicator
              />
            ) : (
              <UploadPreview
                fileName={formik.values.utilityFile?.name ?? ''}
                label="Utility Bill"
                removeFile={() => {
                  formik.setFieldValue('utilityFile', '');
                }}
                requiredIndicator
              />
            )}
            {!formik.values.identificationFile ? (
              <FileUpload
                error={
                  formik.errors.identificationFile
                    ? 'Proof of identification is required'
                    : ''
                }
                label="Proof of Identification"
                fileName="Proofofidentification"
                getFile={(file) => {
                  formik.setFieldValue('identificationFile', file);
                }}
                requiredIndicator
              />
            ) : (
              <UploadPreview
                fileName={formik.values.identificationFile?.name ?? ''}
                label="Proof of Identification"
                removeFile={() => {
                  formik.setFieldValue('identificationFile', '');
                }}
                requiredIndicator
              />
            )}
            {!formik.values.licenseFile ? (
              <FileUpload
                error={formik.errors.licenseFile ? 'License is required' : ''}
                label="License"
                fileName="License"
                getFile={(file) => {
                  formik.setFieldValue('licenseFile', file);
                }}
                requiredIndicator
              />
            ) : (
              <UploadPreview
                fileName={formik.values.licenseFile?.name ?? ''}
                label="License"
                removeFile={() => {
                  formik.setFieldValue('licenseFile', '');
                }}
                requiredIndicator
              />
            )}
          </div>
        </div>
        {/* Director */}
        <div className="mt-12">
          <div className="border-b border-[#0000000D] pb-2">
            <h2 className="text-xl text-[#263238] font-semibold">Director Information</h2>
            <p className="text-base font-normal font-inter text-[#00000066]">
              Please enter director information below
            </p>
          </div>
          <div className="mt-6">
            {formik.values.directors.map((director: Director, index: number) => (
              <div key={index} className="mt-10">
                <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
                  <TextField
                    name={`directors[${index}].fullName`}
                    type="text"
                    value={director.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Director Fullname"
                    error={
                      formik.touched.directors
                        ? formik.errors.directors?.[index] &&
                          formik.errors.directors.length > 0
                          ? JSON.parse(JSON.stringify(formik.errors.directors[index]))
                              .fullName
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                    requiredIndicator
                  />
                  <CustomSelect
                    options={[
                      {
                        value: 'NIN',
                        label: 'National Identification Number (NIN)',
                      },
                      {
                        value: 'Passport Number',
                        label: 'Passport Number',
                      },
                    ]}
                    selectedOption={{
                      value: director.type,
                      label: director.type,
                    }}
                    handleOptionChange={(option) => {
                      formik.setFieldValue(`directors[${index}].type`, option?.value);
                    }}
                    label=" Identification Type"
                    name={`directors[${index}].type`}
                    isSearchable
                    className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                    error={
                      formik.touched.directors
                        ? formik.errors.directors?.[index] &&
                          formik.errors.directors.length > 0
                          ? JSON.parse(JSON.stringify(formik.errors.directors[index]))
                              .type
                          : ''
                        : ''
                    }
                    requiredIndicator
                  />
                  <TextField
                    name={`directors[${index}].number`}
                    type={director.type === 'Passport Number' ? 'text' : 'text'}
                    value={director.number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label={
                      director.type === 'Passport Number'
                        ? 'Passport Number'
                        : director.type === '' || undefined
                        ? 'ID NO'
                        : 'NIN Number'
                    }
                    error={
                      formik.touched.directors
                        ? formik.errors.directors?.[index] &&
                          formik.errors.directors.length > 0
                          ? JSON.parse(JSON.stringify(formik.errors.directors[index]))
                              .number
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                    requiredIndicator
                  />
                </div>
              </div>
            ))}
          </div>
          {formik.values.directors.length > 0 && (
            <div
              role="button"
              tabIndex={0}
              className="flex justify-end w-fit ml-auto"
              onClick={() => deleteDirector()}
            >
              <button
                type="button"
                className="justify-center px-5 py-3 space-x-4 text-white bg-red-700 rounded-2xl item-center"
              >
                <span>Remove</span>
              </button>
            </div>
          )}
          <div
            tabIndex={0}
            className="flex items-center mt-8"
            role="button"
            onClick={addMoreDirector}
          >
            <div className="flex items-center gap-1">
              <Icon name={Icons.Add} />
              <span className="text-sm font-medium text-[#000000]">
                Add another director
              </span>
            </div>
            <div className="ml-5 line" style={{ width: '84%' }} />
          </div>
        </div>
        {/* Respresentative */}
        <div className="mt-12">
          <div className="border-b border-[#0000000D] pb-2">
            <h2 className="text-xl text-[#263238] font-semibold">
              Representative Information
            </h2>
            <p className="text-base font-normal font-inter text-[#00000066]">
              Please enter representative below
            </p>
          </div>
          <div className="mt-6">
            {formik.values.representatives.map((rep, index: number) => (
              <div key={index} className="mt-10">
                <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
                  <TextField
                    name={`representatives[${index}].firstName`}
                    type="text"
                    value={rep.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Representative First Name"
                    error={
                      formik.touched.representatives
                        ? formik.errors.representatives?.[index] &&
                          formik.errors.representatives.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.representatives[index])
                            ).firstName
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                    requiredIndicator
                  />
                  <TextField
                    name={`representatives[${index}].lastName`}
                    type="text"
                    value={rep.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Representative Last Name"
                    error={
                      formik.touched.representatives
                        ? formik.errors.representatives?.[index] &&
                          formik.errors.representatives.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.representatives[index])
                            ).lastName
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                    requiredIndicator
                  />
                  <TextField
                    name={`representatives[${index}].email`}
                    type="text"
                    value={rep.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Representative Email"
                    error={
                      formik.touched.representatives
                        ? formik.errors.representatives?.[index] &&
                          formik.errors.representatives.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.representatives[index])
                            ).email
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                    requiredIndicator
                  />
                  <TextField
                    name={`representatives[${index}].position`}
                    type="text"
                    value={rep.position}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Representative Position"
                    error={
                      formik.touched.representatives
                        ? formik.errors.representatives?.[index] &&
                          formik.errors.representatives.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.representatives[index])
                            ).position
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                    requiredIndicator
                  />
                </div>
              </div>
            ))}
          </div>
          {formik.values.representatives.length > 0 && (
            <div
              role="button"
              tabIndex={0}
              className="flex justify-end "
              onClick={() => deleteRepresentative()}
            >
              <button
                type="button"
                className="justify-center px-5 py-3 space-x-4 text-white bg-red-700 rounded-2xl item-center"
              >
                <span>Remove</span>
              </button>
            </div>
          )}
          <div
            tabIndex={0}
            className="flex items-center mt-8"
            role="button"
            onClick={addMoreRepresentative}
          >
            <div className="flex items-center gap-1">
              <Icon name={Icons.Add} />
              <span className="text-sm font-medium text-[#000000]">
                Add another representative
              </span>
            </div>
            <div className="ml-5 line" style={{ width: '80%' }} />
          </div>
        </div>

        {/* Bank */}
        <div className="mt-12">
          <div className="border-b border-[#0000000D] pb-2">
            <h2 className="text-xl text-[#263238] font-semibold">Banks Information</h2>
            <p className="text-base font-normal font-inter text-[#00000066]">
              Please enter representative below
            </p>
          </div>
          <div className="mt-6">
            {formik.values.banks.map((bank, index: number) => (
              <div key={index} className="mt-10">
                <CustomSelect
                  options={(bankList || []).map((bk) => ({
                    value: bk.code,
                    label: bk.name,
                  }))}
                  selectedOption={{
                    value: bank.bank.value,
                    label: bank.bank.label,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue(`banks[${index}].bank`, option);
                  }}
                  label="Bank Name"
                  name={`banks[${index}].bank`}
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={
                    formik.touched.banks && formik.errors.banks
                      ? 'Please select a bank account'
                      : ''
                  }
                  requiredIndicator
                />
                <div className="grid flex-1 grid-cols-1 mt-10 gap-x-14 gap-y-2 sm:grid-cols-2">
                  <TextField
                    name={`banks[${index}].accountNumber`}
                    type="number"
                    value={bank.accountNumber}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        formik.handleChange(e);
                        handleGetBankDetails(index);
                        setLoadingSpinners((prev) => {
                          const LoadingSpin = [...prev];
                          LoadingSpin[index] =
                            e.target.value.length === 10 && bank.accountName?.length < 1;
                          return LoadingSpin;
                        });
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value.length > 10) {
                        formik.setFieldError(
                          `banks[${index}].accountNumber`,
                          'Account number cannot exceed 10 digits.'
                        );
                      } else {
                        formik.handleChange(e);
                        handleGetBankDetails(index);
                      }
                    }}
                    label="Account Number"
                    error={
                      formik.touched.banks
                        ? formik.errors.banks?.[index] && formik.errors.banks.length > 0
                          ? JSON.parse(JSON.stringify(formik.errors.banks[index]))
                              .accountNumber
                          : ''
                        : ''
                    }
                    className="bg-loadingBgColor"
                    requiredIndicator
                  />
                  <TextField
                    name={`banks[${index}].accountName`}
                    type="text"
                    value={bank.accountName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Account Name"
                    disabled
                    error={
                      formik.touched.banks
                        ? formik.errors.banks?.[index] && formik.errors.banks.length > 0
                          ? JSON.parse(JSON.stringify(formik.errors.banks[index]))
                              .accountName
                          : ''
                        : ''
                    }
                    className="bg-loadingBgColor"
                    loadingSpinner={loadingSpinners[index]}
                    requiredIndicator
                  />
                </div>
              </div>
            ))}
          </div>
          {formik.values.banks.length > 0 && (
            <div
              role="button"
              tabIndex={0}
              className="flex justify-end "
              onClick={() => deleteBank()}
            >
              <button
                type="button"
                className="justify-center px-5 py-3 space-x-4 text-white bg-red-700 rounded-2xl item-center"
              >
                <span>Remove</span>
              </button>
            </div>
          )}
          <div className="flex items-center mt-8">
            <div
              tabIndex={0}
              role="button"
              onClick={addMoreBank}
              className="flex items-center gap-1"
            >
              <Icon name={Icons.Add} />
              <span className="text-sm font-medium text-black">
                Add another bank account
              </span>
            </div>
            <div className="ml-5 line" style={{ width: '80%' }} />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <Button
            type="button"
            variant="outline"
            size="md"
            label="Back"
            className="mt-12 bg-transparent"
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || isSubmitting}
            onClick={handleGoBack}
          />
          <Button
            type="submit"
            variant="primary"
            size="md"
            label="Save & Continue"
            className="mt-12"
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}
