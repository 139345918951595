import { FaMinus, FaPlus } from 'react-icons/fa';
import StateData from '../../../Data/StateData';

function Address({ addressFields, setaddressFields, emptyFields, disabled }: any) {
  const handleAddressChange = (event: any, index: any) => {
    const data: any = [...addressFields];
    data[index][event.target.name] = event.target.value;
    setaddressFields(data);
  };
  const addAddressFields = () => {
    const object = {
      address: '',
      country: '',
      state: '',
      postalCode: '',
      addressType: '',
    };
    setaddressFields([...addressFields, object]);
  };
  const removeAddressFields = (index: any) => {
    const data = [...addressFields];
    data.splice(index, 1);
    setaddressFields(data);
  };
  return (
    <div className="form-body">
      <div className="tag">
        <p>Address</p>
      </div>
      {addressFields.map((form: any, index: any) => (
        <div key={index + 1} className="seti">
          <div className="card">
            <p>Address</p>
            <input
              type="text"
              disabled={disabled}
              id=""
              name="address"
              onChange={(event) => handleAddressChange(event, index)}
              value={form.address}
              className={
                emptyFields.includes('Address') && addressFields[index].address === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className="card">
            <p>Country</p>
            <select
              id=""
              disabled={disabled}
              name="country"
              onChange={(event) => handleAddressChange(event, index)}
              value={form.country}
              className={
                emptyFields.includes('Address') && addressFields[index].country === ''
                  ? 'active'
                  : ''
              }
            >
              <option value="">-Select-</option>
              <option value="nigeria">Nigeria</option>
            </select>
          </div>
          <div className="card">
            <p>State</p>
            <select
              id=""
              disabled={disabled}
              name="state"
              onChange={(event) => handleAddressChange(event, index)}
              value={form.state}
              className={
                emptyFields.includes('Address') && addressFields[index].state === ''
                  ? 'active'
                  : ''
              }
            >
              <option value="">-Select-</option>
              {StateData.map((item) => (
                <option value={item.state.name} key={item.state.name}>
                  {item.state.name}
                </option>
              ))}
            </select>
          </div>
          <div className="card">
            <p>Postal code</p>
            <input
              type="tel"
              disabled={disabled}
              id=""
              name="postalCode"
              onChange={(event) => handleAddressChange(event, index)}
              value={form.postalCode}
              className={
                emptyFields.includes('Address') && addressFields[index].postalCode === ''
                  ? 'active'
                  : ''
              }
            />
          </div>
          <div className="card">
            <p>Address Type</p>
            <select
              id=""
              disabled={disabled}
              name="addressType"
              onChange={(event) => handleAddressChange(event, index)}
              className={
                emptyFields.includes('Address') && addressFields[index].addressType === ''
                  ? 'active'
                  : ''
              }
              value={form.addressType}
            >
              <>
                <option value="">-Select-</option>
                <option value="registeredAddress">Registered Address</option>
                <option value="operatingAddress">Operating Address</option>
              </>
            </select>
            {!disabled && (
              <div className="anothes">
                <div className="remove">
                  <FaMinus onClick={() => removeAddressFields(index)} />
                  <p onClick={() => removeAddressFields(index)}>Remove</p>
                </div>
                <div className="addx">
                  <FaPlus onClick={addAddressFields} />
                  <p onClick={addAddressFields}>Add another</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {addressFields.length === 0 && (
        <>
          {!disabled && (
            <div className="seti">
              <div className="card" style={{ width: '100%' }}>
                <div className="anothes">
                  <div className="remove"></div>
                  <div className="addx">
                    <FaPlus onClick={addAddressFields} />
                    <p onClick={addAddressFields}>Add another</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Address;
