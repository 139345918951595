import React from 'react';
import { components } from 'react-select';
import clsx from 'clsx';
import CreatableSelect from 'react-select/creatable';

const HideIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <></>
    </components.DropdownIndicator>
  );
};

interface IProps {
  options: { label: string; value: string | number; blacklisted?: boolean }[] | undefined;
  selectedOption?: {
    label: string;
    value: string | number;
    blacklisted?: boolean;
  } | null;
  handleOptionChange: (
    option:
      | {
          label: string;
          value: string | number;
          blacklisted?: boolean;
        }
      | {
          label: string;
          value: string | number;
          type: string;
          blacklisted?: boolean;
        }
      | null,
    action: any
  ) => void;
  onInputChange?: (value: any) => void;
  label?: string;
  isRequired?: boolean;
  noOptionsMessage?: string;
  placeholder?: string;
  name?: string;
  isSearchable?: boolean;
  className?: string;
  error?: string;
  isLoading?: boolean;
  requiredIndicator?: boolean;
}

const CustomSelectInput: React.FC<IProps> = ({
  options = [],
  handleOptionChange,
  onInputChange,
  label,
  noOptionsMessage = 'No Options',
  placeholder = 'Select an option',
  name,
  isSearchable = false,
  className,
  error,
  isLoading,
  requiredIndicator,
}) => {
  const controlStyles = {
    base: error
      ? `bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 ${className}`
      : `border bg-white hover:cursor-pointer ${className}`,
    focus: 'border-primary-900 ring-1 ring-primary-900',
    nonFocus: 'border-gray-300 hover:border-gray-400',
  };
  const placeholderStyles = 'text-gray-500 pl-1 py-0.5 z-50';
  const selectInputStyles = 'pl-1 py-0.5 z-50';
  const valueContainerStyles = 'p-1 gap-1 z-50 text-sm font-normal';
  const singleValueStyles = 'leading-7 ml-1 z-50';
  const indicatorsContainerStyles = 'p-1 gap-1 z-50';
  const clearIndicatorStyles =
    'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800 z-50';
  const indicatorSeparatorStyles = 'bg-gray-300s z-50';
  const dropdownIndicatorStyles =
    'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black z-50';
  const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg z-50';
  const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm z-50';
  const optionStyles = {
    base: 'hover:cursor-pointer px-3 py-2 rounded z-50',
    focus: 'bg-gray-100 active:bg-gray-200 z-50',
    selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500 z-50",
  };
  const noOptionsMessageStyles =
    'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm z-50';
  return (
    <div className="w-full">
      <label className="">
        <span className="mb-2 text-[12px] font-medium text-[#353535]">{label}</span>
        {requiredIndicator && (
          <span className="mb-2 text-[12px] font-medium ml-1 text-[red]">*</span>
        )}
      </label>
      <CreatableSelect
        isClearable={true}
        menuPortalTarget={document.body}
        onInputChange={onInputChange}
        unstyled
        styles={{
          input: (base) => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          option: (base, state) => ({
            ...base,
            opacity: state.data.blacklisted ? 0.4 : 1,
            textDecoration: state.data.blacklisted ? 'line-through' : 'none',
          }),
        }}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        isLoading={isLoading}
        classNamePrefix="custom_select"
        name={name}
        placeholder={placeholder}
        options={options}
        isOptionDisabled={(option) => option.blacklisted || false}
        onChange={(option, action) => {
          handleOptionChange(option, action);
        }}
        // onBlur={() => {
        //   handleOptionChange(selectedOption);
        // }}
        noOptionsMessage={() => noOptionsMessage}
        components={{ DropdownIndicator: HideIndicator }}
        isSearchable={isSearchable}
        menuPlacement="auto"
        backspaceRemovesValue
        formatCreateLabel={(inputValue: string) => {
          return `Invite: ${inputValue}`;
        }}
      />
      {error && <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>}
    </div>
  );
};

export default CustomSelectInput;
