import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  approveVendor,
  blacklistVendor,
  bulkAddVendor,
  createVendor,
  declineVendor,
  getVendor,
  getVendors,
  inviteVendor,
  searchVendor,
  updateVendor,
} from '../../service/vendor';
import { BusinessSearchType } from '../../types/vendor.type';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../thunks/account-thunk';

export const useGetVendors = ({
  enabled,
  page,
  type,
  businessId,
}: {
  enabled: boolean;
  page: number;
  type: string;
  businessId: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useQuery(['vendors'], () => getVendors(businessId, page, type), {
    refetchOnWindowFocus: false,
    enabled: enabled,
    onError: (error: Error) => {
      toast.error(
        error.message ?? 'Sorry, an error has occurred while trying to get your vendors'
      );
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useInviteVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, payload }: { businessId: string; payload: any }): Promise<void> => {
      return inviteVendor(businessId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invite-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry, an error has occurred while inviting vendors'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useCreateVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, payload }: { businessId: string; payload: any }): Promise<void> => {
      return createVendor(businessId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry an error has ocurred while trying to create a vendor'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      vendorId,
      payload,
    }: {
      businessId: string;
      vendorId: string;
      payload: any;
    }): Promise<void> => {
      return updateVendor(businessId, vendorId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry, an error has occurred while updating vendors'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useSearchVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    ({
      payload,
      businessID,
    }: {
      payload: any;
      businessID: string;
    }): Promise<BusinessSearchType[]> => {
      return searchVendor(payload, businessID);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['search-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry, an error has occurred while searching for vendors'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetVendor = ({
  enabled,
  vendorId,
  businessId,
}: {
  enabled: boolean;
  vendorId: string;
  businessId: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useQuery(['vendor'], () => getVendor(businessId, vendorId), {
    refetchOnWindowFocus: false,
    enabled,
    onError: (error: Error) => {
      toast.error(
        error.message ?? 'Sorry, an error has occurred while trying to get vendor'
      );
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useBlacklistVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    ({
      businessId,
      vendorId,
      payload,
    }: {
      businessId: string;
      vendorId: string;
      payload: any;
    }): Promise<void> => {
      return blacklistVendor(businessId, vendorId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['blacklist-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry, an error has occurred while blacklisting vendor'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useApproveVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, vendorId }: { businessId: string; vendorId: any }): Promise<void> => {
      return approveVendor(businessId, vendorId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['approve-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry an error has occurred while trying to approve vendor'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useDeclineVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      payload,
      vendorId,
    }: {
      businessId: string;
      payload: any;
      vendorId: any;
    }): Promise<void> => {
      return declineVendor(businessId, payload, vendorId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['decline-vendor']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry! an error has occurred while declining vendor'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useBulkUploadVendor = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, payload }: { businessId: string; payload: any }): Promise<void> => {
      return bulkAddVendor(businessId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bulk-invite-vendor']);
      },
      onError: (error: Error) => {
        toast.error(error.message ?? 'An error has occurred while bulk uploading');
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};
