import React, { useEffect, useState } from 'react';
import { InfoModal } from './InfoModal';
import Button from '../Form/Button';
import TextField from '../Form/TextField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EarlyAccessDto } from '../../types/user.type';
import { useEarlyAccess } from '../../hooks/queries-and-mutations/auth';
import { toast } from 'react-toastify';

type IProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export default function EarlyAccess({ onConfirm, onCancel }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { mutate, isSuccess, isError } = useEarlyAccess();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
      teamSize: '',
      companyWebsite: '',
      vendorCount: '',
      companyDescription: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required').nullable(),
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
      companyName: Yup.string().required('Company name is required').nullable(),
      teamSize: Yup.string().required('Team size is required').nullable(),
      companyWebsite: Yup.string().required('Company website is required').nullable(),
      vendorCount: Yup.string().required('Number of vendor is required').nullable(),
      companyDescription: Yup.string()
        .required('Company description is required')
        .nullable(),
    }),
    onSubmit: (values, { setFieldTouched, resetForm }) => {
      for (const key in values) {
        setFieldTouched(key, true);
      }
      setIsSubmitting(true);
      onsubmit(values, resetForm);
    },
  });

  const onsubmit = (values: EarlyAccessDto, resetForm: () => void) => {
    mutate(values);
    resetForm(); // Reset the form after submission
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      toast.success('Request successfully submitted');
      onConfirm();
    } else if (isError) {
      setIsSubmitting(false);
    }
  }, [isError, isSuccess]);

  return (
    <InfoModal width="w-full sm:w-[45%]" className="h-fit" onClose={onCancel}>
      <div className="rounded-[20px] bg-white p-4 sm:p-8 font-circular text-lg sm:text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center">
          <div className="py-5 text-left max-w-xs sm:max-w-xss">
            <p className="text-lg sm:text-xl font-medium font-inter text-black">
              Be the first to get access to our solution
            </p>
          </div>

          <div className="mx-auto w-full my-8">
            <form className="space-y-2 sm:space-y-0.5" onSubmit={formik.handleSubmit}>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Name"
                  error={formik.touched.name ? formik.errors.name : ''}
                  className="bg-white"
                />
                <TextField
                  name="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Email"
                  error={formik.touched.email ? formik.errors.email : ''}
                  className="bg-white"
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="companyName"
                  type="text"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Company Name"
                  error={formik.touched.companyName ? formik.errors.companyName : ''}
                  className="bg-white"
                />
                <TextField
                  name="teamSize"
                  type="text"
                  value={formik.values.teamSize}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Team Size"
                  error={formik.touched.teamSize ? formik.errors.teamSize : ''}
                  className="bg-white"
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="companyWebsite"
                  type="text"
                  value={formik.values.companyWebsite}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Company Website"
                  error={
                    formik.touched.companyWebsite ? formik.errors.companyWebsite : ''
                  }
                  className="bg-white"
                />
                <TextField
                  name="vendorCount"
                  type="text"
                  value={formik.values.vendorCount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="# of Vendors"
                  error={formik.touched.vendorCount ? formik.errors.vendorCount : ''}
                  className="bg-white"
                />
              </div>
              <textarea
                name="companyDescription"
                rows={5}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                placeholder="Tell us why you want to decline this invoice"
                className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.companyDescription &&
                        formik.errors.companyDescription
                          ? 'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
              />
              <div className="flex items-center gap-3 text-sm mt-2">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(event) => {
                    setIsChecked(event.target.checked);
                  }}
                />
                <p>I accept the terms of use and conditions governing Paytton</p>
              </div>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label="Receive Early Access"
                  className="mt-12"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isChecked}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
