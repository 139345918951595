import React, { Fragment, useEffect, useState } from 'react';
import Button from '../../../../Components/Form/Button';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { selectUserInformation } from '../../../../selectors/account-selector';
import { useGetApproval } from '../../../../hooks/queries-and-mutations/settings';
import { groupDataByLevel } from '../../../../utils';
import { SingleVendorType } from '../../../../types/vendor.type';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import DeclineVendorModal from '../../../../Components/Modal/Vendor/DeclineVendorModal';
import {
  useApproveVendor,
  useDeclineVendor,
} from '../../../../hooks/queries-and-mutations/vendor';
import { toast } from 'react-toastify';

type IProps = {
  vendor: SingleVendorType;
  getVendor: () => void;
};
const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmModal);
const EnhancedDeclineVendorModal = withCreatePortal(DeclineVendorModal);

export default function VendorApprovalFlow({ vendor, getVendor }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showApproveSuccessModal, setShowApproveSuccessModal] = useState(false);

  const { docs } = useAppSelector(selectBusiness);
  const { user } = useAppSelector(selectUserInformation);
  const { data: approvals } = useGetApproval({
    businessId: docs[0]?.businessID?._id,
  });

  const {
    mutate: approveVendor,
    isSuccess: isApproveVendorSuccess,
    isError: isApproveVendorError,
  } = useApproveVendor();
  const {
    mutate: declineVendor,
    isSuccess: isDeclineVendorSuccess,
    isError: isDeclineVendorError,
  } = useDeclineVendor();

  const groupedData = groupDataByLevel(approvals?.docs ?? []);

  //Check if the person has approved
  const checkUserApproval = (approvalUsers: any) => {
    const foundUser = approvalUsers[vendor.sellerApprovalNextLevel as any]?.find(
      (item: any) => item.email === user.email
    );
    if (foundUser) {
      return foundUser;
    } else {
      return null;
    }
  };

  const currentUserApproval = checkUserApproval(groupedData.vendor);

  const handleApprove = () => {
    setIsSubmitting(true);
    approveVendor({
      businessId: docs[0]?.businessID?._id,
      vendorId: vendor.vendorID?._id,
    });
  };

  const handleDecline = (reason: any) => {
    setIsSubmitting(true);
    declineVendor({
      businessId: docs[0]?.businessID?._id,
      vendorId: vendor.vendorID?._id,
      payload: {
        note: reason,
      },
    });
  };

  useEffect(() => {
    if (isApproveVendorSuccess && !isApproveVendorError) {
      setShowConfirmModal(false);
      setShowApproveSuccessModal(true);
      setIsSubmitting(false);
      getVendor();
    } else if (isApproveVendorError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveVendorError, isApproveVendorSuccess]);

  useEffect(() => {
    if (isDeclineVendorSuccess && !isDeclineVendorError) {
      setShowDeclineModal(false);
      setIsSubmitting(false);
      toast.success('You have successfully decline this vendor.');
      getVendor();
    } else if (isDeclineVendorError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeclineVendorError, isDeclineVendorSuccess]);

  return (
    <div>
      <div className="bg-[#B9D6F23D] rounded-2xl p-10 mx-10 mb-5">
        <div className="flex justify-between items-center divide-x divide-black/10">
          <div className="flex justify-between items-center gap-x-16">
            {Object.keys(groupedData.vendor).map((level) => (
              <Fragment key={level}>
                <div className="flex flex-col gap-1">
                  <p className="text-black/50 font-inter font-medium text-sm">
                    Level {level}
                  </p>
                  <div className="flex items-center gap-[15px]">
                    {currentUserApproval?.firstName && currentUserApproval?.lastName ? (
                      <p>
                        {currentUserApproval?.firstName} {currentUserApproval?.lastName}
                      </p>
                    ) : level === '1' ? (
                      groupedData.receivable[level].map((res) => (
                        <p
                          key={res._id}
                          className="text-black font-inter font-medium text-xl"
                        >
                          {res.firstName} {res.lastName}
                        </p>
                      ))
                    ) : (
                      <p className="text-black font-inter font-medium text-xl">
                        {groupedData.receivable[level][0].firstName}{' '}
                        {groupedData.receivable[level][0].lastName}{' '}
                      </p>
                    )}
                  </div>

                  {level === vendor.sellerApprovalNextLevel ? (
                    <div className="whitespace-nowrap text-sm mt-2">
                      <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#F48D14E8] rounded-full"></span>
                      {currentUserApproval
                        ? 'Awaiting your approval'
                        : 'Awaiting approval'}
                    </div>
                  ) : (
                    <div className="whitespace-nowrap text-sm mt-2">
                      <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#216509E8] rounded-full"></span>
                      Approved
                    </div>
                  )}
                </div>
              </Fragment>
            ))}
          </div>

          {checkUserApproval(groupedData.vendor) && (
            <div className="flex gap-6 items-center pl-10">
              <Button
                type="button"
                variant="custom"
                size="custom"
                label="Approve Vendor"
                className="px-5 py-2.5 text-sm bg-[#0353A4] text-white"
                onClick={() => setShowConfirmModal(true)}
              />
              <Button
                type="button"
                variant="custom"
                size="custom"
                label="Decline Vendor"
                className="px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                onClick={() => setShowDeclineModal(true)}
              />
            </div>
          )}
        </div>
      </div>

      {showDeclineModal && (
        <EnhancedDeclineVendorModal
          onSuccess={(values: any) => handleDecline(values)}
          onCancel={() => setShowDeclineModal(false)}
          isSubmitting={isSubmitting}
        />
      )}

      {showApproveSuccessModal && (
        <EnhancedSuccessModal
          title="Vendor approved successfully "
          description="This vendor has been approved"
          cancelText="Back"
          onCancel={() => {
            setShowApproveSuccessModal(false);
          }}
        />
      )}

      {showConfirmModal && (
        <EnhancedConfirmModal
          isSubmitting={isSubmitting}
          content={'Are you sure you want to approve this vendor ?'}
          onConfirm={handleApprove}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Approve"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
        />
      )}
    </div>
  );
}
