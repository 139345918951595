import React, { useMemo } from 'react';
import Button from './Form/Button';
import { Icon, Icons } from './Icon';
import pdfMake from 'pdfmake/build/pdfmake';
import { Poppins } from '../Assests/Poppins';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { InvoiceDoc, InvoiceStatus } from '../types/invoice.type';
import { format } from 'date-fns';
import { formatCurrency } from '../utils';
import Avatar from '../../src/Assests/avatar-1.png';

interface IProps {
  invoice: InvoiceDoc | undefined;
  isValid?: boolean;
}
const PdfDownload = ({ invoice, isValid }: IProps) => {
  const subtotal = useMemo(() => {
    const calculateAmount = (price: number, quantity: number) => {
      return price * quantity;
    };
    let total = 0;
    (invoice?.products ?? []).forEach((product: any) => {
      total += calculateAmount(product?.product?.price || 0, product?.quantity || 0);
    });
    return total;
  }, [invoice?.products]);

  const discountAmount = useMemo(() => {
    if (invoice?.discount?.type === 'percentage') {
      return ((invoice?.discount?.value || 0) / 100) * subtotal;
    } else {
      return invoice?.discount?.value || 0;
    }
  }, [invoice?.discount?.type, invoice?.discount?.value, subtotal]);

  const taxAmount = useMemo(() => {
    if (invoice?.tax?.type === 'percentage') {
      return ((invoice?.tax?.value || 0) / 100) * subtotal;
    } else {
      return invoice?.tax?.value || 0;
    }
  }, [invoice?.tax?.type, invoice?.tax?.value, subtotal]);

  const generatePDF = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    window.pdfMake.vfs['Poppins.ttf'] = Poppins;
    const tableHeaderBgColor = '#5188C0';
    const textColor = '#565C67';
    const textBlack = '#000';
    const textWhite = '#fff';
    const backgroundColor = '#fff';
    const titleColor = '#0353A4';

    pdfMake.fonts = {
      poppins: {
        normal: 'Poppins.ttf',
        bold: 'Poppins.ttf',
      },
    };
    const docDefinition: any = {
      styles: {
        header: {
          fontSize: 20,
          color: titleColor,
          margin: [0, 0, 0, 10],
        },

        entry: {
          margin: [30, 0, 0, 30],
        },

        entries: {
          margin: [0, 20, 0, 20],
        },
        entryHeader: {
          margin: [0, 10, 0, 10],
        },
        footer: {
          margin: [0, 30, 0, 0],
        },
        breakDown: {
          margin: [0, 5, 0, 5],
          fontSize: 10,
        },
        bolderText: {
          color: textBlack,
          fontSize: 12,
          bold: true,
          lineHeight: 1.4,
          alignment: 'left',
        },
        boldText: {
          color: textBlack,
          fontSize: 10,
          bold: true,
          lineHeight: 1.4,
        },
        normalText: {
          color: textColor,
          fontSize: 10,
          lineHeight: 1.4,
        },
        tableExample: {
          color: textColor,
          fontSize: 10,
        },
      },
      background: [
        {
          // Background color
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 595.28,
              h: 841.89,
              color: backgroundColor,
            },
          ],
        },
      ],
      defaultStyle: {
        font: 'poppins',
      },
      content: [
        {
          text: `${invoice?.businessID.name}`,
          style: 'header',
          alignment: 'center',
          decoration: 'underline',
        },

        {
          style: 'entryHeader',
          alignment: 'justify',
          columns: [
            {
              width: '*',
              stack: [
                {
                  text: `Invoice > ${invoice?.number}`,
                  style: 'normalText',
                },
                {
                  image: isValid
                    ? {
                        url: invoice?.businessID?.profileImageURL,
                      }
                    : Avatar,
                  width: 40,
                  height: 40,
                  alignment: 'left',
                  margin: [0, 0, 0, 0],
                },
              ],
            },
            {
              width: '*',
              alignment: 'right',
              stack: [
                {
                  text: `${invoice?.businessID.addresses[0].address}`,
                  style: 'normalText',
                  alignment: 'right',
                },
                {
                  text: `${invoice?.businessID.addresses[0].state}`,
                  style: 'normalText',
                  alignment: 'right',
                },
                {
                  text: `${invoice?.businessID.phone}`,
                  style: 'normalText',
                  alignment: 'right',
                },
                {
                  text: `${invoice?.businessID.email}`,
                  style: 'normalText',
                  alignment: 'right',
                },
              ],
            },
          ],
        },

        {
          width: '*',
          style: 'entries',
          columns: [
            {
              width: '50%',
              alignment: 'left',
              stack: [
                { text: 'Invoice to', style: 'boldText' },
                { text: `${invoice?.sellerName}`, style: 'normalText' },
                {
                  text: `${invoice?.businessID.addresses[0].address}, `,
                  style: 'normalText',
                },
                {
                  text: `${invoice?.businessID.addresses[0].state}`,
                  style: 'normalText',
                },
                { text: `${invoice?.email}`, style: 'normalText' },
              ],
            },
            {
              width: '50%',
              alignment: 'right',
              stack: [
                { text: 'Invoice Details', alignment: 'right', style: 'boldText' },
                {
                  text: `Invoice no: ${invoice?.number}`,
                  alignment: 'right',
                  style: 'normalText',
                },
                {
                  text: `Date: ${format(new Date(invoice?.date || ''), 'dd MMM yyyy')}`,
                  alignment: 'right',
                  style: 'normalText',
                },
                {
                  text: `Due date: ${format(
                    new Date(invoice?.requiredEnd || ''),
                    'dd MMM yyyy'
                  )}`,
                  alignment: 'right',
                  style: 'normalText',
                },
                {
                  text: `Terms of Trade: `,
                  alignment: 'right',
                  style: 'normalText',
                },
              ],
            },
          ],
        },
        {
          layout: {
            hLineWidth: function () {
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
          },
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', 70, '*'],
            fontSize: 10,
            body: [
              [
                {
                  text: 'Product',
                  fillColor: tableHeaderBgColor,
                  color: textWhite,
                  margin: [10, 10, 0, 10],
                },
                {
                  text: 'Description',
                  fillColor: tableHeaderBgColor,
                  color: textWhite,
                  margin: [0, 10, 0, 10],
                },
                {
                  text: 'Unit price',
                  fillColor: tableHeaderBgColor,
                  color: textWhite,
                  alignment: 'center',
                  margin: [0, 10, 10, 0],
                },
                {
                  text: 'Quantity',
                  fillColor: tableHeaderBgColor,
                  color: textWhite,
                  alignment: 'center',
                  margin: [0, 10, 0, 10],
                },
                {
                  text: 'Amount',
                  fillColor: tableHeaderBgColor,
                  color: textWhite,
                  alignment: 'center',
                  margin: [0, 10, 0, 10],
                },
              ],
              ...(invoice?.products.map((res) => [
                {
                  text: `${res.product.name}`,
                  fillColor: '#F5F9FD',
                  margin: [10, 10, 0, 10],
                },
                {
                  text: `${res.product.description}`,
                  fillColor: '#F5F9FD',
                  margin: [0, 10, 0, 10],
                },
                {
                  text: `${formatCurrency(res.product.currency, res.product.price || 0)}`,
                  fillColor: '#F5F9FD',
                  margin: [0, 10, 0, 10],
                  alignment: 'center',
                },
                {
                  text: `${res.quantity}`,
                  fillColor: '#F5F9FD',
                  margin: [0, 10, 0, 10],
                  alignment: 'center',
                },
                {
                  text: `${formatCurrency(
                    res.product.currency,
                    (res.product.price || 0) * res.quantity
                  )}`,
                  fillColor: '#F5F9FD',
                  margin: [0, 10, 0, 10],
                  alignment: 'center',
                },
              ]) || []),
            ],
          },
        },
        {
          width: '40%',
          alignment: 'right',
          style: 'breakDown',
          color: textColor,
          margin: [0, 20, 0, 5],
          columns: [
            {
              text: `Subtotal`,
            },
            {
              width: '*',
              alignment: 'right',
              text: `${formatCurrency(invoice?.currency, subtotal)}`,
            },
          ],
        },
        {
          width: '40%',
          alignment: 'right',
          style: 'breakDown',
          color: textColor,
          columns: [
            {
              text: `Discount`,
            },
            {
              width: '*',
              alignment: 'right',
              text: `${formatCurrency(invoice?.currency, discountAmount)}`,
            },
          ],
        },
        {
          width: '40%',
          alignment: 'right',
          style: 'breakDown',
          color: textColor,
          columns: [
            {
              text: `Tax`,
            },
            {
              width: '*',
              alignment: 'right',
              text: `${formatCurrency(invoice?.currency, taxAmount)}`,
            },
          ],
        },
        {
          columns: [
            { width: '45%', text: '' },
            {
              width: '*',
              alignment: 'right',
              table: {
                widths: ['75%', '*'],
                alignment: 'justify',
                body: [
                  [
                    {
                      text:
                        invoice?.status === 'approved_for_financing' ||
                        invoice?.status === 'outstanding' ||
                        invoice?.status === 'paid'
                          ? 'Outstanding Amount'
                          : 'Total Amount',
                      style: {
                        fontSize: 10,
                        bold: true,
                        color: textColor,
                      },
                      fillColor: '#B9D6F2',
                      margin: [10, 10, 0, 10],
                      alignment: 'justify',
                    },
                    {
                      text: formatCurrency(
                        invoice?.currency,
                        invoice?.outstanding && invoice?.outstanding > 1
                          ? invoice?.outstanding
                          : invoice?.amount
                      ),
                      style: {
                        fontSize: 10,
                        bold: true,
                        color: textBlack,
                      },
                      alignment: 'justify',
                      fillColor: '#B9D6F2',
                      margin: [0, 10, 0, 10],
                    },
                  ],
                ],
              },
              layout: 'noBorders',
            },
            { width: '0', text: '' },
          ],
          margin: [0, 20, 0, 20],
        },

        {
          columns: [
            {
              width: '*',
              stack: [
                {
                  margin: [0, 20, 0, 0],
                  color: textBlack,
                  text: 'Note',
                  style: 'note',
                  alignment: 'left',
                },
                {
                  color: textColor,
                  text: invoice?.note,
                  margin: [0, 5, 0, 10],
                },
              ],
            },
            {
              color: textColor,
              margin: [0, 20, 0, 10],
              width: '60%',
              stack: [
                {
                  text: 'Payment method',
                  style: 'bankDetailsHeading',
                  margin: [0, 0, 0, 10],
                  color: textBlack,
                },
                {
                  columns: [
                    {
                      width: '40%',
                      alignment: 'left',
                      stack: [
                        { text: 'Bank name:', margin: [0, 0, 10, 10], fontSize: 10 },
                        { text: 'Account number:', margin: [0, 5, 10, 10], fontSize: 10 },
                        { text: 'Account name:', margin: [0, 5, 10, 10], fontSize: 10 },
                      ],
                    },
                    {
                      width: '65%',
                      alignment: 'right',
                      stack: [
                        { text: invoice?.bankName, margin: [0, 0, 10, 10], fontSize: 10 },
                        {
                          text: invoice?.bankAccountNumber,
                          margin: [0, 5, 10, 10],
                          fontSize: 10,
                        },
                        {
                          text: invoice?.bankAccountName,
                          margin: [0, 5, 10, 10],
                          fontSize: 10,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      footer: {
        columns: [
          {
            text: 'Powered by Paytton.',
            style: 'normalText',
            alignment: 'center',
            color: tableHeaderBgColor,
          },
        ],
      },
    };

    pdfMake.createPdf(docDefinition).download('paytton_Invoice.pdf');
  };

  return (
    <Button
      type="button"
      variant="transparent"
      size="custom"
      label="Export as PDF"
      className={`px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent text-[#0353A4] ${
        invoice &&
        invoice?.status !== InvoiceStatus.SELLER_INTERNAL_APPROVED &&
        invoice?.status !== InvoiceStatus.PAID &&
        invoice.status !== InvoiceStatus.APPROVED
          ? '!cursor-not-allowed'
          : 'cursor-pointer'
      }`}
      icon={<Icon name={Icons.Download} fill="#8E8E8E80" />}
      iconPosition="right"
      iconLabelSpacing={3}
      onClick={generatePDF}
      disabled={
        invoice &&
        invoice?.status !== InvoiceStatus.SELLER_INTERNAL_APPROVED &&
        invoice?.status !== InvoiceStatus.PAID &&
        invoice.status !== InvoiceStatus.APPROVED
          ? true
          : false
      }
    />
  );
};

export default PdfDownload;
